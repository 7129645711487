import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSubjectList } from "../../../redux/sclassRelated/sclassHandle";
import { deleteUser } from "../../../redux/userRelated/userHandle";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Paper, Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TableTemplate from "../../../components/TableTemplate";
import { BlueButton, GreenButton } from "../../../components/buttonStyles";
import SpeedDialTemplate from "../../../components/SpeedDialTemplate";
import Popup from "../../../components/Popup";

const ShowSubjects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subjectsList, loading, error, response } = useSelector(
    (state) => state.sclass
  );
  const { currentUser, currentRole } = useSelector((state) => state.user);
  const isSuperAdmin = currentRole == "SuperAdmin";
  const adminID =
    currentRole == "SuperAdmin"
      ? localStorage.getItem("SchoolId")
      : currentUser._id;

  useEffect(() => {
    dispatch(getSubjectList(adminID, "AllSubjects"));
  }, [currentUser._id, dispatch]);

  if (error) {
    console.log(error);
  }

  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");

  const deleteHandler = (deleteID, address) => {
    console.log(deleteID);
    console.log(address);
    setMessage("Sorry the delete function has been disabled for now.");
    setShowPopup(true);

    // dispatch(deleteUser(deleteID, address))
    //     .then(() => {
    //         dispatch(getSubjectList(currentUser._id, "AllSubjects"));
    //     })
  };

  const subjectColumns = [
    { id: "subName", label: "Sub Name", minWidth: 170 },
    { id: "subCode", label: "Sub Code", minWidth: 170 },

    // { id: 'sessions', label: 'Sessions', minWidth: 170 },
    { id: "sclassName", label: "Class", minWidth: 170 },
  ];

  const subjectRows = subjectsList.map((subject) => {
    return {
      subName: subject.subName,
      subCode: subject.subCode,
      sclassName: subject.sclassName.sclassName,
      sclassID: subject.sclassName._id,
      id: subject._id,
    };
  });

  const SubjectsButtonHaver = ({ row }) => {
    return (
      <>
        <IconButton onClick={() => deleteHandler(row.id, "Subject")}>
          <DeleteIcon color="error" />
        </IconButton>
        <BlueButton
          variant="contained"
          onClick={() => {

            if(currentRole == "SuperAdmin"){
              navigate(`/SuperAdmin/subjects/subject/${row.sclassID}/${row.id}`)
              return
          }
            navigate(`/Admin/subjects/subject/${row.sclassID}/${row.id}`)}
          }
        >
          View
        </BlueButton>
      </>
    );
  };

  const actions = [
    {
      icon: <PostAddIcon color="primary" />,
      name: "Add New Subject",
      action: () => navigate("/Admin/addsubject/class"),
    },
    {
      icon: <DeleteIcon color="error" />,
      name: "Delete All Subjects",
      action: () => deleteHandler(currentUser._id, "Subjects"),
    },
  ];

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {!isSuperAdmin && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              <GreenButton
                variant="contained"
                onClick={() => navigate("/Admin/addsubject/class")}
              >
                Add Subjects
              </GreenButton>
            </Box>
          )}
          {
            // response ?
            //     <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
            //         <GreenButton variant="contained"
            //             onClick={() => navigate("/Admin/subjects/chooseclass")}>
            //             Add Subjects
            //         </GreenButton>
            //     </Box>
            //     :

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              {Array.isArray(subjectsList) && subjectsList.length > 0 && (
                <TableTemplate
                  buttonHaver={SubjectsButtonHaver}
                  columns={subjectColumns}
                  rows={subjectRows}
                />
              )}
              {/* <SpeedDialTemplate actions={actions} /> */}
            </Paper>
          }
        </>
      )}
      <Popup
        message={message}
        setShowPopup={setShowPopup}
        showPopup={showPopup}
      />
    </>
  );
};

export default ShowSubjects;
