import React, { useEffect, useState } from 'react'
import { getClassStudents, getSubjectDetails, getTopicDetails } from '../../../redux/sclassRelated/sclassHandle';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab, Container, Typography, BottomNavigation, BottomNavigationAction, Paper, Button, Stack } from '@mui/material';
import { BlueButton, GreenButton, PurpleButton } from '../../../components/buttonStyles';
import styled from "styled-components";
import { toast } from 'react-toastify';


const ViewTopic = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch();
  const location = useLocation();

  const { subloading, subjectDetails, topicDetails, sclassStudents, getresponse, error } = useSelector((state) => state.sclass);

  const { id } = params

  useEffect(() => {
    dispatch(getTopicDetails(id, "topic"));
  }, [dispatch, id]);

  if (error) {
    console.log(error)
  }
  console.log(topicDetails)

  const SubjectDetailsSection = () => {
    const numberOfStudents = sclassStudents.length;

    return (
      <>
        <StyledContainer>
          <StyledBox>
            <Stack sx={{
              alignItems: 'center',
              mb: 3
            }}>
              <span style={{ fontSize: '20px', fontWeight: '500' }}>Topic Details</span>

            </Stack>
            <Stack spacing={2}>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Topic Name</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {topicDetails?.topicName}</span>
                </div>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Topic Code</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {topicDetails?.topicCode}</span>
                </div>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Topic Subtitle</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {topicDetails?.topicSubTitle}</span>
                </div>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Subject</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {topicDetails?.subject?.subName}</span>
                </div>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Topic Description</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {topicDetails?.topicDescription}</span>
                </div>
              </div>
             {!location.pathname.startsWith('/Student') && <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Topic Document</span>
                </div>
                <div>
                  : <GreenButton variant="contained"
                    style={{ marginLeft: '0px' }}
                    onClick={() => {
                      if (topicDetails?.topicDocuments?.length == 0) {
                        toast.error("Document not found");
                        return;
                      }
                      Promise.all(
                        topicDetails?.topicDocuments?.map(async (item) => {
                          const link = document.createElement("a")
                          link.href = item?.Location
                          link.setAttribute("download", "document.pdf") //or any other extension
                           document.body.appendChild(link)
                           link.click();
                           document.body.removeChild(link);
                        })
                      )
                      
                     
                    }}>
                    Download Document
                  </GreenButton>
                </div>

              </div>}



            </Stack>
            <Button style={{ marginTop: 20 }} variant="outlined" onClick={() => navigate(-1)}>
              Go Back
            </Button>
          </StyledBox>
        </StyledContainer>
      </>
    );
  }

  return (
    <>
      {subloading ?
        < div > Loading...</div >
        :
        <>

          <SubjectDetailsSection />

        </>
      }
    </>
  )
}

export default ViewTopic


const StyledContainer = styled(Box)`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledBox = styled(Box)`
  width: 600px;
  padding: 50px 3rem 50px;
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
`;