import React from 'react';
import Carousel from './HomePageComponents/carousel';
import Banner from './HomePageComponents/banner';
import MiddleScreen from './HomePageComponents/middleScreen';
import Plans from './HomePageComponents/Plans';
import BottomScreen from './HomePageComponents/bottomScreen';
import './HomePageComponents/Homepage.css';
// import { useDeviceType } from "../components/deviceType";
import Assessment from './HomePageComponents/Assessment';
import MultiModal from './HomePageComponents/MultiModal';
import LastBox from './HomePageComponents/lastBox';
import Footer from './HomePageComponents/footer';
import NavBar from './HomePageComponents/NavBar';

const Homepage = () => {
    return (
        <div className='main-container' >
        <NavBar />
        <Banner />
        <Carousel />
        <MiddleScreen />
        <Plans />
        <BottomScreen />
        <Assessment />
        <MultiModal />
        <LastBox />
        <Footer />
    </div>
        // <StyledContainer>
        //     <Grid container spacing={0}>
        //         <Grid item xs={12} md={6}>
        //             <img src={Students} alt="students" style={{ width: '100%' }} />
        //         </Grid>
        //         <Grid item xs={12} md={6}>
        //             <StyledPaper elevation={3}>
        //                 <StyledTitle>
        //                     Welcome to
        //                     <br />
        //                     School Management
        //                     <br />
        //                     System
        //                 </StyledTitle>
        //                 <StyledText>
        //                     Streamline school management, class organization, and add students and faculty.
        //                     Seamlessly track attendance, assess performance, and provide feedback.
        //                     Access records, view marks, and communicate effortlessly.
        //                 </StyledText>
        //                 <StyledBox>
        //                     <StyledLink
        //                         //  to="/choose"
        //                         to="/Adminlogin"
        //                     >
        //                         <LightPurpleButton variant="contained" fullWidth>
        //                             Login
        //                         </LightPurpleButton>
        //                     </StyledLink>
        //                     {/* <StyledLink to="/chooseasguest">
        //                         <Button variant="outlined" fullWidth
        //                             sx={{ mt: 2, mb: 3, color: "#7f56da", borderColor: "#7f56da" }}
        //                         >
        //                             Login as Guest
        //                         </Button>
        //                     </StyledLink> */}
        //                     <StyledText>
        //                         Don't have an account?{' '}
        //                         <Link to="/Adminregister" style={{ color: "#550080" }}>
        //                             Sign up
        //                         </Link>
        //                     </StyledText>
        //                 </StyledBox>
        //             </StyledPaper>
        //         </Grid>
        //     </Grid>
        // </StyledContainer>
    );
};

export default Homepage;

// const StyledContainer = styled(Container)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
// `;

// const StyledPaper = styled.div`
//   padding: 24px;
//   height: 100vh;
// `;

// const StyledBox = styled(Box)`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content:center;
//   gap: 16px;
//   padding: 24px;
// `;

// const StyledTitle = styled.h1`
//   font-size: 3rem;
//   color: #252525;
//   /* font-family: "Manrope"; */
//   font-weight: bold;
//   padding-top: 0;
//   letter-spacing: normal;
//   line-height: normal;
// `;

// const StyledText = styled.p`
//   /* color: #550080; */
//   margin-top: 30px;
//   margin-bottom: 30px; 
//   letter-spacing: normal;
//   line-height: normal;
// `;

// const StyledLink = styled(Link)`
//   text-decoration: none;
// `;
