import React, { useEffect } from 'react';
import { getTeacherDetails } from '../../../redux/teacherRelated/teacherHandle';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import styled from "styled-components";


const TeacherDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { loading, teacherDetails, error } = useSelector((state) => state.teacher);

    const teacherID = params.id;

    useEffect(() => {
        dispatch(getTeacherDetails(teacherID));
    }, [dispatch, teacherID]);

    if (error) {
        console.log(error);
    }

    const isSubjectNamePresent = teacherDetails?.teachSubject?.subName;

    const handleAddSubject = () => {
        navigate(`/Admin/teachers/choosesubject/${teacherDetails?.teachSclass?._id}/${teacherDetails?._id}`);
    };

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                // <Container>
                //     <Typography variant="h4" align="center" gutterBottom>
                //         Teacher Details
                //     </Typography>
                //     <Typography variant="h6" gutterBottom>
                //         Teacher Name: {teacherDetails?.name}
                //     </Typography>
                //     <Typography variant="h6" gutterBottom>
                //         Class Name: {teacherDetails?.teachSclass?.sclassName}
                //     </Typography>
                //     {isSubjectNamePresent ? (
                //         <>
                //             <Typography variant="h6" gutterBottom>
                //                 Subject Name: {teacherDetails?.teachSubject?.subName}
                //             </Typography>
                //             <Typography variant="h6" gutterBottom>
                //                 Subject Sessions: {teacherDetails?.teachSubject?.sessions}
                //             </Typography>
                //         </>
                //     ) : (
                //         <Button variant="contained" onClick={handleAddSubject}>
                //             Add Subject
                //         </Button>
                //     )}
                // </Container>

                <>
                    <StyledContainer>
                        <StyledBox>
                            <Stack sx={{
                                alignItems: 'center',
                                mb: 3
                            }}>
                                <span style={{ fontSize: '20px', fontWeight: '500' }}>Teacher Details</span>
                                {/* <img
                            src={Classroom}
                            alt="classroom"
                            style={{ width: '80%' }}
                        /> */}
                            </Stack>
                            <Stack spacing={2}>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <div style={{ width: '250px' }}>
                                        <span style={{ fontSize: '16px', }}>Teacher Name</span>
                                    </div>
                                    <div>
                                        <span style={{ fontSize: '16px', }}>: {teacherDetails?.name}</span>
                                    </div>
                                </div>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <div style={{ width: '250px' }}>
                                        <span style={{ fontSize: '16px', }}>Class Name</span>
                                    </div>
                                    <div>
                                        <span style={{ fontSize: '16px', }}>: {teacherDetails?.teachSclass?.sclassName}</span>
                                    </div>
                                </div>
                                {isSubjectNamePresent ? (
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <div style={{ width: '250px' }}>
                                            <span style={{ fontSize: '16px', }}>Subject Name</span>
                                        </div>
                                        <div>
                                            <span style={{ fontSize: '16px', }}>: {teacherDetails?.teachSubject?.subName}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <Button variant="contained" onClick={handleAddSubject}>
                                        Add Subject
                                    </Button>
                                )}


                            </Stack>
                            <Button style={{ marginTop: 20 }} variant="outlined" onClick={() => navigate(-1)}>
                                Go Back
                            </Button>
                        </StyledBox>
                    </StyledContainer>
                </>
            )}
        </>
    );
};

export default TeacherDetails;


const StyledContainer = styled(Box)`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledBox = styled(Box)`
  width: 600px;
  padding: 50px 3rem 50px;
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
`;