import React from 'react';
import { Container, Grid, Box, } from '@mui/material';
import { useDeviceType } from "../../components/deviceType";
import './Homepage.css';
import maskOne from "../../assets/maskOne.png";
import multiModal from "../../assets/multiModal.png";
import RoundCheckbox from "../../components/RoundCheckbox";
import { PurpleButton } from '../../components/buttonStyles';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LastBox = () => {
    const deviceType = useDeviceType();
    return <>
        <Grid container spacing={0} justifyContent="center" style={{ padding: '5%', marginTop: deviceType != "desktop" ? "10%" : 0, marginBottom: deviceType != "desktop" ? "10%" : 0 }}>
            <Grid style={{ order: 1 }} justifyContent="center" alignItems="center" item xs={12} md={12}>
                <div style={{
                    ...styles.paper,
                    marginRight: deviceType == "desktop" ? '15%' : '1%',
                    marginLeft: deviceType == "desktop" ? '15%' : '1%',
                    paddingLeft: deviceType == "desktop" ? '15%' : '1%',
                    paddingRight: deviceType == "desktop" ? '15%' : '1%',
                }}>
                    <p style={{ ...styles.text, fontSize: deviceType == "mobile" ? '1.2rem' : '1.4rem' }}>Book a free meeting with a tutor today and find out how they can help your child</p>
                    <div style={{ marginTop: '5%', marginBottom: '5%' }}>
                        <StyledBox>
                            <StyledLink to="/Adminlogin">
                                <PurpleButton variant="contained" fullWidth>
                                    Get Started
                                </PurpleButton>
                            </StyledLink>
                        </StyledBox>
                    </div>
                </div>
            </Grid>
        </Grid>
    </>
}
export default LastBox;

const StyledPaper = styled.div`
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  margin-top: '1.25rem';
  margin-bottom: '0.625rem';
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
const styles = {
    paper: {
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        borderColor: "#d3d3d3",
        padding: '2%',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    text: {
        fontSize: '1.4rem',
        color: '#6321A6',
        textAlign: "left",
        paddingLeft: 10,
        fontWeight: '500',
        paddingLeft: 10,
        textAlign: "center",
        marginBottom: '0.625rem',
    },

}