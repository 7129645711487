import React, { useEffect, useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { PurpleButton } from '../../components/buttonStyles';
import ixiTutorLogo from "../../assets/ixiTutorLogo.png";
import './Homepage.css';
import DrawerComponent from './DrawerComponent';
import PopupContainer from './PopupContainer';

import { useDeviceType } from "../../components/deviceType";
import { useNavigate } from 'react-router-dom';
const pages = [
  { name: 'About ixi Tutor', subpages: ['Overview', 'Team', 'Mission'] },
  { name: 'Features', subpages: ['Feature 1', 'Feature 2', 'Feature 3'] },
  { name: 'Solutions', subpages: ['Solution 1', 'Solution 2', 'Solution 3'] },
  { name: 'Plans', subpages: ['Plan 1', 'Plan 2', 'Plan 3'] },
  { name: 'Resources', subpages: ['Resource 1', 'Resource 2', 'Resource 3'] },
];

function NavBar() {
  const [anchorElUser, setAnchorElUser] = React.useState(Array(pages.length).fill(null));
  const menuRefs = useRef(Array(pages.length).fill(null));
  const deviceType = useDeviceType();
  const [clickedPage, setClickedPage] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const handleItemClick = (page) => {
  };
  const handleSubItemClick = (subpage) => {
  };
  const handleOpenUserMenu = (index, event) => {
    setClickedPage(null);
    const updatedAnchorElUser = [...anchorElUser];
    updatedAnchorElUser[index] = event.currentTarget;
    setAnchorElUser(updatedAnchorElUser);
  };
  const handleCloseUserMenu = (index) => {
    const updatedAnchorElUser = [...anchorElUser];
    updatedAnchorElUser[index] = null;
    setAnchorElUser(updatedAnchorElUser);
    setClickedPage(null);
  };
  const handleMenuItemClick = (subpages) => {
    setClickedPage(subpages);
  };

  const handleMenuItemHover = (subpages) => {
    setClickedPage(subpages);
  };

  const handleMenuItemLeave = () => {
    setClickedPage(null);
  };
  return (
    <AppBar position="fixed" sx={{
      backgroundColor: 'white',
      backgroundImage: 'linear-gradient(to bottom right, rgba(230, 230, 250, 0.5), rgba(147, 112, 219, 0.5))',
    }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={ixiTutorLogo}
            alt="Description of the image"
            width="auto"
            height="40"
            style={{ paddingLeft: "1.875rem", margin: "auto" }}
          />
          {deviceType === 'desktop' ? (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {pages.map((page, index) => (
                <React.Fragment key={page.name}>
                  {page.name !== 'Close' && (
                    <React.Fragment>
                      <Button
                        onMouseEnter={(event) => { handleOpenUserMenu(index, event) }}
                        sx={{
                          my: 2,
                          color: 'black',
                          display: 'block',
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                        onClick={(event) => { handleOpenUserMenu(index, event) }}
                      >
                        {page.name}
                      </Button>

                      {anchorElUser[index] && (
                        <PopupContainer
                          name={page.name}
                          open={Boolean(anchorElUser[index])}
                          anchorEl={anchorElUser[index]}
                          onClose={() => handleCloseUserMenu(index)}
                          data={{ subpages: page?.subpages }}
                        />
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </Box>
          ) : (
            <>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleDrawerOpen}
                  color='black'
                  sx={{
                    marginLeft: 'auto',
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <DrawerComponent open={openDrawer} onClose={handleDrawerClose} drawerData={pages} handleItemClick={handleItemClick} handleSubItemClick={handleSubItemClick} />
              </Box>
            </>
          )}
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <Tooltip title="Click to login">
              <IconButton sx={{ p: 0 }}>
                <PurpleButton variant="contained" fullWidth onClick={() => {navigate("/Adminlogin")}}>
                  Login
                </PurpleButton>
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
      <div className="wave"></div>
    </AppBar>
  );
}

export default NavBar;
