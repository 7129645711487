import React, { useEffect, useState } from "react";
import { Button, TextField, Grid, Box, Typography, CircularProgress, Stack } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addStuff } from '../../../redux/userRelated/userHandle'; 
import { underControl } from '../../../redux/userRelated/userSlice';
import Popup from '../../../components/Popup';
import axios from "../../../utils/axios";
import axiosIn from "axios";

import styledCom from "styled-components";
import { getSubjectList } from "../../../redux/sclassRelated/sclassHandle";
import { toast } from "react-toastify";


const TopicForm = () => {
    const [subjects, setSubjects] = useState([{ topicName: "", topicCode: "", topicDescription: "", topicSubTitle: "" ,topicDocuments:[]}]);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    const userState = useSelector(state => state.user);
    const { status, currentUser, response, error } = userState;
    const [className, setClassName] = useState('')
    const [sclassName, setSclassName] = useState('')
    const [classId, setClassId] = useState(null)

    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedFileList, setSelectedFileList] = useState([])
    const [fileUploading, setFileUploading] = useState(false)


    // const sclassName = params.id
    const adminID = currentUser._id
    const address = "Topic"

    const [showPopup, setShowPopup] = useState(false);
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false)


    const { subjectsList } = useSelector((state) => state.sclass);

    useEffect(() => {
        dispatch(getSubjectList(currentUser._id, "AllSubjects"));
    }, [currentUser._id, dispatch]);

    const handleSubjectNameChange = (index) => (event) => {
        const newSubjects = [...subjects];
        newSubjects[index].topicName = event.target.value;
        setSubjects(newSubjects);
    };

    const handleSubjectCodeChange = (index) => (event) => {
        const newSubjects = [...subjects];
        newSubjects[index].topicCode = event.target.value;
        setSubjects(newSubjects);
    };
    const handleSubjectDescriptionChange = (index) => (event) => {
        const newSubjects = [...subjects];
        newSubjects[index].topicDescription = event.target.value;
        setSubjects(newSubjects);
    };
    const handleSubjectSubtitleChange = (index) => (event) => {
        const newSubjects = [...subjects];
        newSubjects[index].topicSubTitle = event.target.value;
        setSubjects(newSubjects);
    };

    const changeHandler = (event) => {
        if (event.target.value === 'Select Subject') {
            setClassName('Select Subject');
            setSclassName('');
        } else {
            const selectedClass = subjectsList.find(
                (classItem) => (classItem.subName + ' - ' + classItem.sclassName?.sclassName) == event.target.value
            );
            setClassName(selectedClass.subName + ' - ' + selectedClass.sclassName?.sclassName);
            setSclassName(selectedClass._id);
            setClassId(selectedClass.sclassName?._id)
        }
    }

    const handleAddSubject = () => {
        setSubjects([...subjects, { subName: "", subCode: "" }]);
    };

    const handleRemoveSubject = (index) => () => {
        const newSubjects = [...subjects];
        newSubjects.splice(index, 1);
        setSubjects(newSubjects);
    };

    const fields = {
        subject: sclassName,
        topics: subjects.map((subject) => ({
            topicName: subject.topicName,
            topicCode: subject.topicCode,
            topicDescription: subject.topicDescription,
            topicSubTitle: subject.topicSubTitle,
            topicDocuments: subject.topicDocuments,
            sclassName:classId
        })),
        adminID,
    };

    const uploadFile = async (file, index) => {
        
        setSelectedFileList(file)
        const promises = [];
        for (let i = 0; i < file.length; i++) {
            let formdata = new FormData();
            formdata.append('file', file[i]);
            
            // formdata.append("modulename", "post")
            const respon = axios.post('upload', formdata, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            promises.push(respon)
            // Do something with each file, such as upload to server or process it.
        }
        // file?.map((item) => {
        //     let formdata = new FormData();
        //     formdata.append('file', item);
        //     // formdata.append("modulename", "post")
        //     const respon = axios.post('upload', formdata, {
        //         headers: { 'Content-Type': 'multipart/form-data' },
        //     });
        //     promises.push(respon)
        // });
        setFileUploading(true)
        // var formdata = new FormData();
        // formdata.append('file', file);
        const responses = await Promise.all(promises);
        responses.forEach(item => {
            if (item?.status == 200) {
                // setResponce(arr => arr == null ? [item?.data] : [...arr, item?.data]);
                setSelectedFile(file[0])
               
                setSubjects(prev => {
                    const newSubjects = [...prev];
                    newSubjects[index].topicDocuments = [...newSubjects[index].topicDocuments, item?.data];
                    return newSubjects;
                })
                
                setFileUploading(false);
            } else {
                setFileUploading(false)
            }
        });
        // if (status == 200) {
        //     setSelectedFile(file)
        //     const newSubjects = [...subjects];
        //     newSubjects[index].topicDocuments = [data];
        //     setSubjects(newSubjects);
        //     setFileUploading(false);
        // }
        setFileUploading(false)
    }

    const uploadFileInFolder = async (file) => {
        try {
            if (!selectedFile) {
                toast.error("Please select the file")
                return false
            }
            const selectedClass = subjectsList.find(
                (classItem) => classItem._id === sclassName
            );
            var formdata = new FormData();
            formdata.append('documents', selectedFile);
            formdata.append('collection_id', selectedClass?.folderDetails?.collection);
            formdata.append('folder_id', selectedClass?.folderDetails?.id);
            formdata.append('short_description', subjects[0]?.topicDescription);
            formdata.append('topic_name', subjects[0]?.topicName);

            const { data, status } = await axiosIn.post('https://lms.ixicities.com/api/v1/lms/emdedding/doc/', formdata, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (status == 200 || status == 201) {
                return true
            }
            return false
        } catch (e) {
            return false
        }

    }

    const submitHandler = async (event) => {
        event.preventDefault();
        if (sclassName == '') return;
        if(selectedFile == null) {
            toast.error("File not uploaded, Retry!")
            return
        }
        setLoader(true)
        const finalResponse = await uploadFileInFolder()
        if (finalResponse) {
            dispatch(addStuff(fields, address))
            setLoader(false)
            return
        }
        toast.error("Something went wrong while uploading document")
        setLoader(false)

    };

    useEffect(() => {
        if (status === 'added') {
            navigate("/Admin/topics");
            dispatch(underControl())
            setLoader(false)
        }
        else if (status === 'failed') {
            setMessage(response)
            setShowPopup(true)
            setLoader(false)
        }
        else if (status === 'error') {
            setMessage("Network Error")
            setShowPopup(true)
            setLoader(false)
        }
    }, [status, navigate, error, response, dispatch]);

    return (
        <StyledContainer>
            <StyledBox>
                <form onSubmit={submitHandler}>
                    <Stack sx={{
                        alignItems: 'center',
                        mb: 3
                    }}>
                        <span style={{ fontSize: '20px', fontWeight: '500' }}>Add Topic</span>

                    </Stack>
                    {subjects.map((subject, index) => (
                        <Stack spacing={2} key={index}>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Topic Name"
                                    variant="outlined"
                                    value={subject.topicName}
                                    onChange={handleSubjectNameChange(index)}
                                    sx={styles.inputField}
                                    required
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Topic Code"
                                    variant="outlined"
                                    value={subject.topicCode}
                                    onChange={handleSubjectCodeChange(index)}
                                    sx={styles.inputField}
                                    required
                                />
                            </Grid>
                            <select
                                style={{
                                    border: '1px solid black',
                                    height: '56px', borderRadius: '4px'
                                }}
                                className="registerInput"
                                value={className}
                                onChange={changeHandler} required>
                                <option value='Select Subject'>Select Subject</option>
                                {subjectsList.map((classItem, index) => (
                                    <option key={index} value={classItem.subName + ' - ' + classItem.sclassName?.sclassName}>
                                        {classItem.subName} - {classItem.sclassName?.sclassName}
                                    </option>
                                ))}
                            </select>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Topic Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    value={subject.topicDescription}
                                    onChange={handleSubjectDescriptionChange(index)}
                                    sx={styles.inputField}
                                    required
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Topic Subtitle"
                                    variant="outlined"
                                    value={subject.topicSubTitle}
                                    onChange={handleSubjectSubtitleChange(index)}
                                    sx={styles.inputField}
                                    required
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    component="label"
                                >
                                    Upload Document
                                    <input
                                        type="file"
                                        hidden
                                        
                                        accept="application/pdf"
                                        onChange={(e) => {
                                           
                                            uploadFile(e?.target?.files, index)
                                        }}
                                        multiple
                                    />
                                </Button>
                                {
                                      selectedFileList?.length > 0 &&   <span>{selectedFileList?.length} selected</span>
                               }
                            </Grid>
                            {/* <Grid item xs={6}>
                            <Box display="flex" alignItems="flex-end">
                                {index === 0 ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleAddSubject}
                                    >
                                        Add Topic
                                    </Button>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={handleRemoveSubject(index)}
                                    >
                                        Remove
                                    </Button>
                                )}
                            </Box>
                        </Grid> */}
                        </Stack>
                    ))}
                    <Grid item xs={12} style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
                        <Box display="flex" justifyContent="flex-start">
                            <Button variant="contained" color="primary" type="submit" disabled={loader}>
                                {loader || fileUploading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Box>
                    </Grid>
                    <Popup message={message} setShowPopup={setShowPopup} showPopup={showPopup} />
                </form>
            </StyledBox>
        </StyledContainer>
    );
}

export default TopicForm

const styles = {
    inputField: {
        '& .MuiInputLabel-root': {
            color: '#838080',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#838080',
        },
    },
};

const StyledContainer = styledCom(Box)`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledBox = styledCom(Box)`
  width: 600px;
  padding: 50px 3rem 50px;
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
`;