import { useState } from 'react';
import {
    CssBaseline,
    Box,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import StudentSideBar from './SideBar';
import { Navigate, Route, Routes } from 'react-router-dom';
import Logout from '../Logout'
import AccountMenu from '../../components/AccountMenu';
import { AppBar, Drawer } from '../../components/styles';
import SuperAdminHomePage from './HomePage';
import Academies from './Academies';
import ViewSchoolDetails from './ViewSchoolDetails';
import ShowSubjects from '../admin/subjectRelated/ShowSubjects';
import ViewSubject from '../admin/subjectRelated/ViewSubject';
import ChooseClass from '../admin/teacherRelated/ChooseClass';
import SubjectForm from '../admin/subjectRelated/SubjectForm';
import StudentAttendance from '../admin/studentRelated/StudentAttendance';
import StudentExamMarks from '../admin/studentRelated/StudentExamMarks';
import ShowTopics from '../admin/topicRelated/ShowTopic';
import TopicForm from '../admin/topicRelated/TopicForm';
import ViewTopic from '../admin/topicRelated/ViewTopic';
import AddClass from '../admin/classRelated/AddClass';
import ShowClasses from '../admin/classRelated/ShowClasses';
import ClassDetails from '../admin/classRelated/ClassDetails';
import AddStudent from '../admin/studentRelated/AddStudent';
import ShowStudents from '../admin/studentRelated/ShowStudents';
import ViewStudent from '../admin/studentRelated/ViewStudent';
import ChooseSubject from '../admin/teacherRelated/ChooseSubject';
import ShowTeachers from '../admin/teacherRelated/ShowTeachers';
import TeacherDetails from '../admin/teacherRelated/TeacherDetails';
import AddTeacher from '../admin/teacherRelated/AddTeacher';
import Plans from './Plans';
import PlanForm from './AddPlan';
import ViewPlan from './ViewPlan';




const SuperAdminDashboard = () => {
    const [open, setOpen] = useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar open={open} position='absolute'>
                    <Toolbar sx={{ pr: '24px' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            SuperAdmin Dashboard
                        </Typography>
                        <AccountMenu />
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open} sx={open ? styles.drawerStyled : styles.hideDrawer}>
                    <Toolbar sx={styles.toolBarStyled}>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <StudentSideBar />
                    </List>
                </Drawer>
                <Box component="main" sx={styles.boxStyled}>
                    <Toolbar />
                    <Routes>
                        <Route path="/" element={<SuperAdminHomePage />} />
                        <Route path='*' element={<Navigate to="/" />} />
                        <Route path="/SuperAdmin/dashboard" element={<SuperAdminHomePage />} />
                        <Route path="/SuperAdmin/academies" element={<Academies />} />
                        <Route path="/SuperAdmin/academyDetails/:id" element={<ViewSchoolDetails />} />


                         {/* Subject */}
                         <Route path="/SuperAdmin/subjects" element={<ShowSubjects />} />
                        <Route path="/SuperAdmin/subjects/subject/:classID/:subjectID" element={<ViewSubject />} />
                        <Route path="/SuperAdmin/subjects/chooseclass" element={<ChooseClass situation="Subject" />} />

                        <Route path="/SuperAdmin/addsubject/:id" element={<SubjectForm />} />
                        <Route path="/SuperAdmin/class/subject/:classID/:subjectID" element={<ViewSubject />} />

                        <Route path="/SuperAdmin/subject/student/attendance/:studentID/:subjectID" element={<StudentAttendance situation="Subject" />} />
                        <Route path="/SuperAdmin/subject/student/marks/:studentID/:subjectID" element={<StudentExamMarks situation="Subject" />} />


                        {/* Topic */}
                        <Route path="/SuperAdmin/topics" element={<ShowTopics />} />
                        <Route path="/SuperAdmin/addtopic/:id" element={<TopicForm />} />
                        <Route path="/SuperAdmin/topics/topic/:id" element={<ViewTopic />} />

                       
                        
                        
                        {/* Class */}
                        <Route path="/SuperAdmin/addclass" element={<AddClass />} />
                        <Route path="/SuperAdmin/classes" element={<ShowClasses />} />
                        <Route path="/SuperAdmin/classes/class/:id" element={<ClassDetails />} />
                        <Route path="/SuperAdmin/class/addstudents/:id" element={<AddStudent situation="Class" />} />

                        {/* Student */}
                        <Route path="/SuperAdmin/addstudents" element={<AddStudent situation="Student" />} />
                        <Route path="/SuperAdmin/students" element={<ShowStudents />} />
                        <Route path="/SuperAdmin/students/student/:id" element={<ViewStudent />} />
                        <Route path="/SuperAdmin/students/student/attendance/:id" element={<StudentAttendance situation="Student" />} />
                        <Route path="/SuperAdmin/students/student/marks/:id" element={<StudentExamMarks situation="Student" />} />

                        {/* Teacher */}
                        <Route path="/SuperAdmin/topic/choosesubject" element={<ChooseSubject situation="topic" />} />
                        <Route path="/SuperAdmin/teachers" element={<ShowTeachers />} />
                        <Route path="/SuperAdmin/teachers/teacher/:id" element={<TeacherDetails />} />
                        <Route path="/SuperAdmin/teachers/chooseclass" element={<ChooseClass situation="Teacher" />} />
                        <Route path="/SuperAdmin/teachers/choosesubject/:id" element={<ChooseSubject situation="Norm" />} />
                        <Route path="/SuperAdmin/teachers/choosesubject/:classID/:teacherID" element={<ChooseSubject situation="Teacher" />} />
                        <Route path="/SuperAdmin/teachers/addteacher/:id" element={<AddTeacher />} />


                        {/* Plans */}

                        <Route path="/SuperAdmin/plans" element={<Plans />} />
                        <Route path="/SuperAdmin/plans/addPlan" element={<PlanForm />} />
                        <Route path="/SuperAdmin/plans/view/:id" element={<ViewPlan />} />


                    
                        <Route path="/logout" element={<Logout />} />
                    </Routes>
                </Box>
            </Box>
        </>
    );
}

export default SuperAdminDashboard

const styles = {
    boxStyled: {
        backgroundColor: (theme) =>
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    toolBarStyled: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
    },
    drawerStyled: {
        display: "flex"
    },
    hideDrawer: {
        display: 'flex',
        '@media (max-width: 600px)': {
            display: 'none',
        },
    },
}