import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSubjectList } from '../../redux/sclassRelated/sclassHandle';
import { BottomNavigation, BottomNavigationAction, Container, Paper, Table, TableBody, TableHead, Typography } from '@mui/material';
import { getUserDetails } from '../../redux/userRelated/userHandle';
import TableTemplate from '../../components/TableTemplate';
import { BlueButton } from '../../components/buttonStyles';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';

const Academies = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(false)
    const [subjectsList,setSubjectList] = useState(null)
    
    const getSubjectsList = async () => {
        setLoading(true)
        const {data,status} = await axios.get("SuperAdmin/school/list");
        if(status == 200){
            setSubjectList(data)
        }
        setLoading(false)
    }
    useEffect(() => {
        getSubjectsList()
    },[])
    const subjectColumns = [
        { id: 'schoolName', label: 'School name', minWidth: 170 },
        { id: 'name', label: 'Username', minWidth: 170 },

        // { id: 'sessions', label: 'Sessions', minWidth: 170 },
        { id: 'email', label: 'Email', minWidth: 170 },
        { id: 'status', label: 'Status', minWidth: 170 },

    ]

    const subjectRows = subjectsList?.map((subject) => {
        return {
            schoolName: subject.schoolName,
            name: subject.name,
            status: subject.status,
            email: subject.email,
            id: subject._id,
        };
    })

    const SubjectsButtonHaver = ({ row }) => {
        return (
            <>
                <BlueButton variant="contained"
                    onClick={() => navigate(`/SuperAdmin/academyDetails/${row.id}`)}
                >
                    View
                </BlueButton>
                {/* <BlueButton variant="contained"
                    style={{ marginLeft: 10 }}
                    // onClick={() => navigate(`/Student/subjects/topics/${row.id}`)}
                >
                    Topics
                </BlueButton> */}
            </>
        );
    };

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : 
                <div>

                    <>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            {Array.isArray(subjectsList) && subjectsList.length > 0 &&
                                <TableTemplate buttonHaver={SubjectsButtonHaver} columns={subjectColumns} rows={subjectRows} />
                            }
                        </Paper>
                    </>
                </div>
            }
        </>
    );
};

export default Academies;