import React, { useEffect, useState } from "react";
import { Button, TextField, Grid, Box, Typography, CircularProgress, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addStuff } from '../../../redux/userRelated/userHandle';
import { underControl } from '../../../redux/userRelated/userSlice';
import Popup from '../../../components/Popup';
import axios from "../../../utils/axios";
import moment from "moment";
import { toast } from "react-toastify";

import styledCom from "styled-components";
import { getAllSclasses } from "../../../redux/sclassRelated/sclassHandle";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const SubjectForm = () => {
    const [subjects, setSubjects] = useState([{ subName: "", subCode: "", sessions: "", subImage: null }]);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { sclassesList } = useSelector((state) => state.sclass);
    const [className, setClassName] = useState('')
    const [sclassName, setSclassName] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)

    const userState = useSelector(state => state.user);
    const { status, currentUser, response, error } = userState;

    // const sclassName = params.id
    const adminID = currentUser._id
    const address = "Subject"

    const [showPopup, setShowPopup] = useState(false);
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false)

    const handleSubjectNameChange = (index) => (event) => {
        const newSubjects = [...subjects];
        newSubjects[index].subName = event.target.value;
        setSubjects(newSubjects);
    };

    const handleSubjectCodeChange = (index) => (event) => {
        const newSubjects = [...subjects];
        newSubjects[index].subCode = event.target.value;
        setSubjects(newSubjects);
    };

    useEffect(() => {
        dispatch(getAllSclasses(adminID, "Sclass"));
    }, [adminID, dispatch]);

    const changeHandler = (event) => {
        if (event.target.value === 'Select Class') {
            setClassName('Select Class');
            setSclassName('');
        } else {
            const selectedClass = sclassesList.find(
                (classItem) => classItem.sclassName === event.target.value
            );
            setClassName(selectedClass.sclassName);
            setSclassName(selectedClass._id);
        }
    }


    const handleSessionsChange = (index) => (event) => {
        const newSubjects = [...subjects];
        newSubjects[index].sessions = event.target.value || 0;
        setSubjects(newSubjects);
    };

    const handleAddSubject = () => {
        setSubjects([...subjects, { subName: "", subCode: "" }]);
    };

    const handleRemoveSubject = (index) => () => {
        const newSubjects = [...subjects];
        newSubjects.splice(index, 1);
        setSubjects(newSubjects);
    };

    const fields = {
        sclassName,
        subjects: subjects.map((subject) => ({
            subName: subject.subName,
            subCode: subject.subCode,
            sessions: subject.sessions,
            subImage: subject.subImage
        })),
        adminID,
    };

    const submitHandler = (event) => {
        event.preventDefault();
        if (fields?.subjects?.length > 0 && fields?.subjects[0]?.subImage == null) {
            toast.error("Please upload Subject image")
            return
        }
        setLoader(true)

        console.log(fields)
        dispatch(addStuff(fields, address))
    };

    const uploadFile = async (file, index) => {
        
        var formdata = new FormData();
        formdata.append('file', file);
        const { data, status } = await axios.post('upload', formdata, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (status == 200) {
            setSelectedFile(file)
            const newSubjects = [...subjects];
            newSubjects[index].subImage = data;
            setSubjects(newSubjects);
        }
        console.log(data)
    }

    useEffect(() => {
        if (status === 'added') {
            navigate("/Admin/subjects");
            dispatch(underControl())
            setLoader(false)
        }
        else if (status === 'failed') {
            setMessage(response)
            setShowPopup(true)
            setLoader(false)
        }
        else if (status === 'error') {
            setMessage("Network Error")
            setShowPopup(true)
            setLoader(false)
        }
    }, [status, navigate, error, response, dispatch]);

    return (
        <StyledContainer>
            <StyledBox>
                <form onSubmit={submitHandler}>
                    <Stack sx={{
                        alignItems: 'center',
                        mb: 3
                    }}>
                        <span style={{ fontSize: '20px', fontWeight: '500' }}>Add Subject</span>

                    </Stack>
                    {subjects.map((subject, index) => (
                        <Stack spacing={2} key={index}>
                            <TextField
                                fullWidth
                                label="Subject Name"
                                variant="outlined"
                                value={subject.subName}
                                onChange={handleSubjectNameChange(index)}
                                sx={styles.inputField}
                                required
                            />


                            <select
                                style={{
                                    border: '1px solid black',
                                    height: '56px',borderRadius:'4px'
                                }}
                                className="registerInput"
                                value={className}
                                onChange={changeHandler} required>
                                <option value='Select Class'>Select Class</option>
                                {sclassesList.map((classItem, index) => (
                                    <option key={index} value={classItem.sclassName}>
                                        {classItem.sclassName}
                                    </option>
                                ))}
                            </select>



                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Subject Code"
                                    variant="outlined"
                                    value={subject.subCode}
                                    onChange={handleSubjectCodeChange(index)}
                                    sx={styles.inputField}
                                    required
                                />
                            </Grid>
                            {/* <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Sessions"
                                variant="outlined"
                                type="number"
                                inputProps={{ min: 0 }}
                                value={subject.sessions}
                                onChange={handleSessionsChange(index)}
                                sx={styles.inputField}
                                required
                            />
                        </Grid> */}
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    component="label"
                                >
                                    Upload Subject Image
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(e) => {
                                            uploadFile(e?.target?.files[0], index)
                                            console.log(e?.target?.files[0])
                                        }}
                                    />
                                </Button>
                                {selectedFile && <span>{selectedFile?.name}</span>}
                            </Grid>
                            {/* <Grid item xs={6}>
                            <Box display="flex" alignItems="flex-end">
                                {index === 0 ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleAddSubject}
                                    >
                                        Add Subject
                                    </Button>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={handleRemoveSubject(index)}
                                    >
                                        Remove
                                    </Button>
                                )}
                            </Box>
                        </Grid> */}
                        </Stack>
                    ))}
                    <Grid item xs={12} mt={2} style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
                        <Box display="flex" justifyContent="flex-start">
                            <Button variant="contained" color="primary" type="submit" disabled={loader}>
                                {loader ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Box>
                    </Grid>
                    <Popup message={message} setShowPopup={setShowPopup} showPopup={showPopup} />
                </form>
            </StyledBox>
        </StyledContainer>
    );
}

export default SubjectForm

const styles = {
    inputField: {
        '& .MuiInputLabel-root': {
            color: '#838080',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#838080',
        },
    },
};

const StyledContainer = styledCom(Box)`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledBox = styledCom(Box)`
  width: 600px;
  padding: 50px 3rem 50px;
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
`;