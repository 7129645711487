import React from 'react';
import { Container, Grid, Box, } from '@mui/material';
import { useDeviceType } from "../../components/deviceType";
import './Homepage.css';
import maskOne from "../../assets/maskOne.png";
import multiModal from "../../assets/multiModal.png";
import RoundCheckbox from "../../components/RoundCheckbox";
const Assessment = () => {
    const deviceType = useDeviceType();

    const objects = [
        "Text-based lessons and assessments",
        "Handwritten input recognition",
        "Speech-to-text for oral responses",
        "Image and video explanations",
        "Voice-based explanations"
    ];

    const ObjectDetails = ({ innerArray }) => {
        return (
            <div>
                {innerArray.map((item, idx) => (
                    <div style={styles.flexRowBox} key={idx}>
                        <RoundCheckbox checked={false} />
                        <p style={{ ...styles.text3 }}>{item}</p>
                    </div>
                ))}
            </div>
        );
    };
    return <>
        <Grid container spacing={0} justifyContent="center" alignItems="center" style={{
            padding: '5%',
            backgroundColor: '#F9F4FF',
            marginTop: deviceType != "desktop" ? "10%" : 0
        }}>
            <Grid style={{ order: 1 }} justifyContent="center" alignItems="center" item xs={12} md={6}>
                <div style={{ ...styles.paper, backgroundColor: "#F5F3F3", width: '100%', }}>
                    <img src={multiModal} alt="students" style={{ ...styles.image, height: "auto" }} />
                </div>
            </Grid>
            <Grid style={{ order: 2, paddingLeft: '5%', marginTop: deviceType != "desktop" ? "10%" : 0 }} justifyContent="center" alignItems="center" item xs={12} md={6}>
                <p style={styles.text2}>Multimodal Learning</p>
                <p style={styles.text1}>Catering to Every Learner</p>
                <ObjectDetails innerArray={objects} />
            </Grid>
        </Grid>

    </>
}
export default Assessment;

const styles = {
    paper: {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: 5,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    image: {
        width: "100%",
        height: '40vh',
        display: 'block',
        margin: 'auto'
    },
    imageBox: {
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        borderColor: "#d3d3d3",
        padding: '2%',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    text3: {
        fontSize: '0.75rem',
        color: '#6321A6',
        textAlign: "left",
        marginLeft: -10
    },
    flexRowBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingLeft: 10,
        marginBottom: -10
    },
    text2: {
        fontSize: '1.4rem',
        color: 'black',
        textAlign: "left",
        paddingLeft: 10,
        fontWeight: '500',
        paddingLeft: 10,
    },
    text1: {
        fontSize: '0.8rem',
        color: 'black',
        textAlign: "left",
        paddingLeft: 10,
        fontWeight: '400',
        marginBottom: 10
    },
}