import React from "react";
import { Grid } from '@mui/material';
import landing from "../../assets/landing.png";
import student from "../../assets/students.svg";
import { useDeviceType } from "../../components/deviceType";
import './Homepage.css';
const MiddleScreen = () => {
    const deviceType = useDeviceType();
    return <>
        <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ marginTop: deviceType != "desktop" ? "10%" : "5%" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <h2>
                        Generative AI Model
                    </h2>
                    <p>The Core of Personalized Education</p>
                </div>
            </div>
        </Grid >

        <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ padding: '5%', paddingTop: "1%" }}>
            <Grid style={{ order: 1, padding: '2%', marginTop: deviceType != "desktop" ? "10%" : 0 }} item xs={12} md={6}>
                <div style={styles.paper}>
                    <div style={styles.title}>
                        Training Data
                    </div>
                    <div style={styles.text}>
                        Curriculum content uploaded by schools, diverse examples of student answers and assessments.
                    </div>
                </div>
            </Grid>
            <Grid style={{ order: 2, padding: '2%' }} item xs={12} md={6}>
                <div style={{ ...styles.paper, backgroundColor: "#F5F3F3", boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '2%' }}>
                    <img src={landing} alt="students" style={{ width: '50%', height: "auto", backgroundColor: "transparent", display: 'block', margin: 'auto' }} />
                </div>
            </Grid>
            <Grid style={{ order: deviceType != "desktop" ? 3 : 4, padding: '2%', marginTop: deviceType != "desktop" ? "10%" : 0 }} item xs={12} md={6}>
                <div style={styles.paper}>
                    <div style={styles.title}>
                        Functionalities
                    </div>
                    <div style={styles.text}>
                        Generate introductory course descriptions, frame questions for
                        assessments, evaluate student answers, analyze historical data, create images and videos to aid explanations.
                    </div>
                </div>
            </Grid>
            <Grid style={{ order: deviceType != "desktop" ? 4 : 3, padding: '2%' }} item xs={12} md={6}>
                <div style={{ ...styles.paper, backgroundColor: "#F5F3F3", boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '2%' }}>
                    <img src={student} alt="students" style={{ width: '50%', height: "auto", backgroundColor: "transparent", display: 'block', margin: 'auto' }} />
                </div>
            </Grid>
        </Grid >
    </>
}
export default MiddleScreen;


const styles = {
    title: {
        fontSize: '1.8rem',
        color: '#6321A6',
        fontWeight: 'bold',
    },
    text: {
    },
    paper: {
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8
    }
};
