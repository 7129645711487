import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDeviceType } from "../../components/deviceType";
import { Switch, FormControlLabel } from "@mui/material";
import RoundCheckbox from "../../components/RoundCheckbox";
import "./Homepage.css";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";
const Plans = () => {
  const deviceType = useDeviceType();
  const [toggleValue, setToggleValue] = useState(false);
  const [hoverValue, setHoverValue] = useState("");
  const [plans, setPlans] = useState(null);
  const navigate = useNavigate()
  const handleToggleChange = () => {
    setToggleValue(!toggleValue);
  };
  const objects = [
    [
      "One Admin",
      "20 Teachers Login",
      "1000 Students Login",
      "1000 Parents Login",
      "1000 Documents submission",
      "Unlimited Chats",
    ],
    [
      "One Admin",
      "40 Teachers Login",
      "2000 Students Login",
      "2000 Parents Login",
      "5000 Documents submission",
      "Unlimited Chats",
    ],
    [
      "One Admin",
      "100 Teachers Login",
      "10000 Students Login",
      "10000 Parents Login",
      "Unlimited Documents submission",
      "Unlimited Chats",
    ],
  ];
  const ObjectDetails = ({ innerArray, objectValue }) => {
    return (
      <div>
        <div style={styles.flexRowBox}>
          <RoundCheckbox checked={objectValue == hoverValue ? true : false} />
          <p
            style={{
              ...styles.text3,
              color: objectValue == hoverValue ? "white" : "#6321A6",
            }}
          >
            One Admin
          </p>
        </div>
        <div style={styles.flexRowBox}>
          <RoundCheckbox checked={objectValue == hoverValue ? true : false} />
          <p
            style={{
              ...styles.text3,
              color: objectValue == hoverValue ? "white" : "#6321A6",
            }}
          >
            {innerArray?.noOfTeachers} Teachers Login
          </p>
        </div>
        <div style={styles.flexRowBox}>
          <RoundCheckbox checked={objectValue == hoverValue ? true : false} />
          <p
            style={{
              ...styles.text3,
              color: objectValue == hoverValue ? "white" : "#6321A6",
            }}
          >
            {innerArray?.noOfStudents} Students Login
          </p>
        </div>

        <div style={styles.flexRowBox}>
          <RoundCheckbox checked={objectValue == hoverValue ? true : false} />
          <p
            style={{
              ...styles.text3,
              color: objectValue == hoverValue ? "white" : "#6321A6",
            }}
          >
            {innerArray?.noOfParents} Parents Login
          </p>
        </div>

        <div style={styles.flexRowBox}>
          <RoundCheckbox checked={objectValue == hoverValue ? true : false} />
          <p
            style={{
              ...styles.text3,
              color: objectValue == hoverValue ? "white" : "#6321A6",
            }}
          >
             {innerArray?.noOfDocumentSubmission} Documents submission
          </p>
        </div>
        <div style={styles.flexRowBox}>
          <RoundCheckbox checked={objectValue == hoverValue ? true : false} />
          <p
            style={{
              ...styles.text3,
              color: objectValue == hoverValue ? "white" : "#6321A6",
            }}
          >
            {innerArray?.noOfChats} Chats
          </p>
        </div>
        
      </div>
    );
  };

  const getPlanList = async () => {
    const { data, status } = await axios.get("PlanList");
    if (status == 200) {
      setPlans(data);
    }
  };

  useEffect(() => {
    getPlanList();
  }, []);
  return (
    <>
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: deviceType == "mobile" ? "15%" : 0 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <h2>Choose the plan that fits your needs.</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
              aliquam, purus sit
            </p>
          </div>
        </div>
      </Grid>
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        style={{
          marginTop: deviceType == "mobile" ? "8%" : "1%",
          marginBottom: deviceType == "mobile" ? "8%" : "1%",
        }}
      >
        <div style={styles.parentDiv}>
          <div
            style={{
              ...styles.toggleBox,
              background: !toggleValue
                ? "linear-gradient(to top right, #8A2BE2, #4B0082)"
                : "white",
            }}
            onClick={() => {
              setToggleValue(false);
            }}
          >
            <p style={{ color: toggleValue ? "#9C40F7" : "white" }}>
              Standard Cover
            </p>
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: 63,
              marginLeft: 15,
              marginRight: 30,
              marginTop: -10,
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={toggleValue}
                  onChange={handleToggleChange}
                  sx={{
                    width: 100,
                    "& .MuiSwitch-switchBase": {
                      transitionDuration: "300ms",
                      "&.Mui-checked": {
                        transform: "translateX(50px)",
                      },
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: "#9C40F7",
                      height: "25px",
                      borderRadius: "12px",
                      width: "100px", // Fixed width for the track
                    },
                    "& .MuiSwitch-thumb": {
                      backgroundColor: "#ffffff",
                      padding: "1px",
                      top: "5px",
                      margin: "4.5px",
                    },
                    "&.Mui-checked": {
                      "& .MuiSwitch-track": {
                        backgroundColor: "#2196f3",
                      },
                    },
                  }}
                />
              }
            />
          </div>
          <div
            style={{
              ...styles.toggleBox,
              background: toggleValue
                ? "linear-gradient(to top right, #8A2BE2, #4B0082)"
                : "white",
            }}
            onClick={() => {
              setToggleValue(true);
            }}
          >
            <p style={{ color: !toggleValue ? "#9C40F7" : "white" }}>
              Premium Cover
            </p>
          </div>
        </div>
      </Grid>
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        style={{ paddingRight: "5%", paddingLeft: "5%" }}
      >
        {plans?.map((item, index) => {
          return (
            <Grid style={{ order: 1, padding: "1%" }} item xs={12} md={4}>
              <div
                style={{
                  ...styles.flexBox,
                  background:
                    hoverValue == index
                      ? "linear-gradient(to top right, #8A2BE2, #4B0082)"
                      : "white",
                }}
                onMouseEnter={() => {
                  setHoverValue(index);
                }}
                onClick={() => {
                  setHoverValue(index);
                }}
              >
                <div style={styles.flexRow}>
                  <div
                    style={{
                      ...styles.title1,
                      color: hoverValue != index ? "#6321A6" : "white",
                    }}
                  >
                    {item?.name}
                  </div>
                  <p
                    style={{
                      ...styles.title1,
                      color: hoverValue != index ? "#6321A6" : "white",
                    }}
                  >
                    ${item?.price}/<br />
                    <p
                      style={{
                        ...styles.text1,
                        color: hoverValue != index ? "#6321A6" : "white",
                      }}
                    >
                      Month
                    </p>
                  </p>
                </div>
                <p style={styles.text2}>What’s included</p>
                <div>
                  <ObjectDetails innerArray={item} objectValue={index} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                  onClick={() => {
                    navigate(`/Adminregister?plan=${item?._id}`)
                    // alert("Plan 1 selected");
                  }}
                >
                  <div
                    style={{
                      ...styles.button,
                      background:
                        hoverValue == index
                          ? "white"
                          : "linear-gradient(to top right, #8A2BE2, #4B0082)",
                    }}
                  >
                    <p
                      style={{
                        ...styles.text2,
                        textAlign: "center",
                        margin: "auto",
                        color: hoverValue == index ? "#6321A6" : "white",
                      }}
                    >
                      Get Today
                    </p>
                  </div>
                </div>
                <p
                  style={{
                    ...styles.text4,
                    color: hoverValue == index ? "white" : "#0094FF",
                  }}
                >
                  YBC Terms & Conditions
                </p>
              </div>
            </Grid>
          );
        })}
        {/* <Grid style={{ order: 2, padding: '1%' }} item xs={12} md={4}>
                <div style={{ ...styles.flexBox, background: hoverValue == "Two" ? 'linear-gradient(to top right, #8A2BE2, #4B0082)' : "white" }} onMouseEnter={() => { setHoverValue("Two") }} onClick={() => { setHoverValue("Two") }}>
                    <div style={styles.flexRow}>

                        <div style={{ ...styles.title1, color: hoverValue != "Two" ? "#6321A6" : "white" }}>
                            Plan 2
                        </div>
                        <p style={{ ...styles.title1, color: hoverValue != "Two" ? "#6321A6" : "white" }}>
                            $189/<br /><p style={{ ...styles.text1, color: hoverValue != "Two" ? "#6321A6" : "white" }}>Month</p>
                        </p>
                    </div>
                    <p style={styles.text2}>What’s included</p>
                    <div>
                        {objects.map((object, index) => (
                            <div key={index}>
                                {index === 1 && <ObjectDetails innerArray={object} objectValue={"Two"} />}
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }} onClick={() => { alert("Plan 2 selected") }}>
                        <div style={{ ...styles.button, background: hoverValue == "Two" ? "white" : 'linear-gradient(to top right, #8A2BE2, #4B0082)' }}>
                            <p style={{ ...styles.text2, textAlign: "center", margin: "auto", color: hoverValue == "Two" ? "#6321A6" : "white" }}>Get Today</p>
                        </div>
                    </div>
                    <p style={{ ...styles.text4, color: hoverValue == "Two" ? 'white' : "#0094FF" }}>YBC Terms & Conditions</p>
                </div>
            </Grid>
            <Grid style={{ order: 3, padding: '1%' }} item xs={12} md={4}>
                <div style={{ ...styles.flexBox, background: hoverValue == "Three" ? 'linear-gradient(to top right, #8A2BE2, #4B0082)' : "white", }} onMouseEnter={() => { setHoverValue("Three") }} onClick={() => { setHoverValue("Three") }}>
                    <div style={styles.flexRow}>

                        <div style={{ ...styles.title1, color: hoverValue != "Three" ? "#6321A6" : "white" }}>
                            Plan 3
                        </div>
                        <p style={{ ...styles.title1, color: hoverValue != "Three" ? "#6321A6" : "white" }}>
                            $1000/<br /><p style={{ ...styles.text1, color: hoverValue != "Three" ? "#6321A6" : "white" }}>Month</p>
                        </p>
                    </div>
                    <p style={styles.text2}>What’s included</p>
                    <div>
                        {objects.map((object, index) => (
                            <div key={index}>
                                {index === 2 && <ObjectDetails innerArray={object} objectValue={"Three"} />}
                            </div>
                        ))}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }} onClick={() => { alert("Plan 3 selected") }}>
                        <div style={{ ...styles.button, background: hoverValue == "Three" ? "white" : 'linear-gradient(to top right, #8A2BE2, #4B0082)' }}>
                            <p style={{ ...styles.text2, textAlign: "center", margin: "auto", color: hoverValue == "Three" ? "#6321A6" : "white" }}>Get Today</p>
                        </div>
                    </div>
                    <p style={{ ...styles.text4, color: hoverValue == "Three" ? 'white' : "#0094FF" }}>YBC Terms & Conditions</p>
                </div>
            </Grid> */}
      </Grid>
    </>
  );
};
export default Plans;

const styles = {
  title: {
    fontSize: "1.8rem",
    color: "#6321A6",
    /* fontFamily: "Manrope", */
    fontWeight: "bold",
    paddingTop: 20,
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  text: {
    /* color: #550080; */
    // marginTop: '30px',
    // marginBottom: '30px',
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  paper: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  parentDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    marginLeft: "5%",
    marginRight: "5%",
  },
  toggleBox: {
    paddingTop: 5,
    paddingBottom: 5,
    lineHeight: 0.9,
    paddingRight: 15,
    paddingLeft: 15,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 8,
    borderColor: "#9C40F7",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    // marginLeft: "5%",
    padding: "5%",
    marginTop: "-5%",
  },
  flexBox: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 25,
    borderStyle: "solid",
    borderColor: "#D3D3D3",
    width: "90%",
    padding: "5%",
    // paddingTop: "-5%",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  },
  flexRowBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 10,
    marginBottom: -10,
  },
  title1: {
    fontSize: "1.5rem",
    color: "#6321A6",
    /* fontFamily: "Manrope", */
    fontWeight: "bold",
    paddingTop: 20,
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  text1: {
    fontSize: "0.8rem",
    color: "#6321A6",
    textAlign: "right",
  },
  text2: {
    fontSize: "1rem",
    color: "black",
    textAlign: "left",
    paddingLeft: 10,
    fontWeight: "500",
  },
  text3: {
    fontSize: "0.75rem",
    color: "#6321A6",
    textAlign: "left",
    marginLeft: -10,
  },
  text4: {
    fontSize: "0.75rem",
    color: "#0094FF",
    textAlign: "center",
    textDecoration: "underline",
  },
  button: {
    width: "15.625rem",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    marginTop: "0.625rem",
    marginBottom: "0.625rem",
    borderRadius: "5.625rem",
    backgroundColor: "#6321A6",
    alignSelf: "center",
  },
};
