import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getClassTopicsList, getSubjectList, getTopicsList } from '../../redux/sclassRelated/sclassHandle';
import { BottomNavigation, BottomNavigationAction, Container, IconButton, Paper, Table, TableBody, TableHead, Typography } from '@mui/material';
import { getUserDetails } from '../../redux/userRelated/userHandle';
import CustomBarChart from '../../components/CustomBarChart'
import Modal from 'react-modal';

import InsertChartIcon from '@mui/icons-material/InsertChart';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { StyledTableCell, StyledTableRow } from '../../components/styles';
import TableTemplate from '../../components/TableTemplate';
import { BlueButton } from '../../components/buttonStyles';
import { useNavigate, useParams } from 'react-router-dom';
import SpeedDialTemplate from '../../components/SpeedDialTemplate';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DeleteIcon from "@mui/icons-material/Delete";
import axios from '../../utils/axios';
import { useDeviceType } from '../../components/deviceType';
import { useMediaQuery } from '@mui/material';
const customStyles = {
    box: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        borderColor: "#d3d3d3",
        padding: "5%",
    },
    button: {
        minWidth: "100%",
    }
};
// Modal.setAppElement('#app');


const StudentTopics = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const deviceType = useDeviceType();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { topicsList, sclassDetails } = useSelector((state) => state.sclass);
    const { userDetails, currentUser, loading, response, error } = useSelector((state) => state.user);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [rowData, setRowData] = React.useState(null);
    const [subjects, setSubjects] = React.useState([]);
    const params = useParams()

    const { id } = params
    useEffect(() => {
        dispatch(getUserDetails(currentUser._id, "Student"));
    }, [dispatch, currentUser._id])

    if (response) { console.log(response) }
    else if (error) { console.log(error) }

    const [subjectMarks, setSubjectMarks] = useState([]);
    const [selectedSection, setSelectedSection] = useState('table');

    useEffect(() => {
        if (userDetails) {
            setSubjectMarks(userDetails.examResult || []);
        }
    }, [userDetails])

    // useEffect(() => {
    //     // if (subjectMarks === []) {
    //     dispatch(getClassTopicsList(currentUser.sclassName._id, "ClassTopics"));
    //     // }
    // }, [subjectMarks, dispatch, currentUser.sclassName._id]);


    const classSubjects = async () => {
        try {
            const { data, status } = await axios.get(`topics?subject=${id}`);
            if (status == 200) {
                setSubjects(data)
                return
            }
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        classSubjects();
    }, [])

    const SubjectsButtonHaver = ({ row }) => {
        return (
            <div>
                {/* <BlueButton variant="contained"
                    onClick={() => navigate(`/Student/topics/topic/${row.id}`)}
                >
                    View
                </BlueButton>
                <div style={{ marginRight: 10, marginTop: 10 }}></div> */}
                <BlueButton variant="contained"
                    // onClick={() => navigate(`/Student/freeflow`, { state: row })}
                    onClick={() => {
                        setRowData(row)
                        setIsOpen(true);
                    }}


                >
                    Start Lesson
                </BlueButton>
                {/* <SpeedDialTemplate actions={actions} /> */}

            </div>
        );
    };
    const subjectColumns = [
        { id: 'topicName', label: 'Topic Name', },
        { id: 'topicCode', label: 'Topic Code', },
        { id: 'topicDescription', label: 'Topic Description', },
        { id: 'topicSubTitle', label: 'Topic Subtitle', },

        { id: 'subject', label: 'Subject' },
        // { id: 'school', label: 'School' },


        // { id: 'sclassName', label: 'Class', minWidth: 170 },
    ]


    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }



    const subjectRows = subjects.map((subject) => {
        return {
            topicName: subject.topicName,
            topicCode: subject.topicCode,
            topicCode: subject.topicCode,
            topicDescription: subject.topicDescription,
            topicSubTitle: subject.topicSubTitle,
            subject: subject.subject?.subName,
            // school: subject.school?.name,
            id: subject._id,
        };
    })




    return (
        <>

            {loading ? (
                <div>Loading...</div>
            ) : (
                <div>

                    {/* {selectedSection === 'table' && renderTableSection()} */}
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        {Array.isArray(subjects) && subjects.length > 0 &&
                            <TableTemplate buttonHaver={SubjectsButtonHaver} columns={subjectColumns} rows={subjectRows} />
                        }
                        {/* <SpeedDialTemplate actions={actions} /> */}
                    </Paper>


                </div>
            )}

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: deviceType == "mobile" ? "90%" : deviceType == "tablet" ? "60%" : "40%",
                        marginLeft: deviceType == "tablet" && !isMobile ? "5%" : "0%",
                        zIndex: 100,
                        borderRadius: 8,
                        padding: '10px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        backgroundColor: "#ffffff",
                    }
                }}
                contentLabel="Example Modal"

            >
                <div style={{ ...customStyles.box, padding: "1%", backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div style={{ ...customStyles.box, backgroundColor: "#ffffff" }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: "#7815DB", fontWeight: 'bold', width: "100%", textAlign: "center", marginBottom: 20 }}>Choose your option for lesson</span>
                            <BlueButton style={customStyles.button} onClick={() => {
                                navigate(`/Student/freeflow`, { state: rowData })
                            }} variant="contained">
                                Freeflow
                            </BlueButton>
                            <div style={{ marginTop: 20 }}></div>
                            <BlueButton style={customStyles.button} onClick={() => {
                                navigate(`/Student/interactive`, { state: rowData })
                            }} variant="contained">
                                Interactive
                            </BlueButton>
                            <div style={{ marginTop: 20 }}></div>
                            <BlueButton style={customStyles.button} variant="contained">
                                Start Assignment
                            </BlueButton>
                        </div>
                    </div>
                </div>
            </Modal >
        </>
    );
};

export default StudentTopics;