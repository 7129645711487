import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import ixiTutorLogo from "../../assets/ixiTutorLogo.png";

const DrawerComponent = ({ open, onClose, drawerData, handleItemClick, handleSubItemClick }) => {
    const [openDropdown, setOpenDropdown] = useState(null);

    const handleDropdownToggle = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };
    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            sx={{
                width: '60%',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: '60%',
                    boxSizing: 'border-box',
                    border: '1px solid #ccc', // Border style
                    backgroundColor: '#fff', // Background color
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Elevation style
                },
            }}
        >
            <img
                src={ixiTutorLogo}
                alt="Description of the image"
                width="90%"
                height="50px"
                style={{ marginRight: "auto", marginLeft: "auto", height: 50, marginBottom: 20, marginTop: 20 }}
            />
            <List>
                {drawerData.map((page, index) => (
                    <div key={index} style={{
                        border: '1px solid #ccc', borderRadius: '4px', marginBottom: '4px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', background: openDropdown === index ? 'linear-gradient(to top right, #8A2BE2, #4B0082)' : "white",
                    }}>
                        <ListItem
                            button
                            onClick={() => handleDropdownToggle(index)}
                            style={{ color: openDropdown === index ? "white" : "black" }}
                        >
                            <ListItemText primary={page.name} />
                            {page.subpages && (openDropdown === index ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
                        </ListItem>
                        <Collapse in={openDropdown === index} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {page.subpages && page.subpages.map((subpage, subIndex) => (
                                    <ListItem
                                        key={subIndex}
                                        button
                                        onClick={() => handleSubItemClick(subpage)}
                                        style={{
                                            paddingRight: 15,
                                            textAlign: 'right',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.3)',
                                            background: openDropdown === index ? '#f3f3f3' : "white",
                                            marginRight: "2%",
                                            marginLeft: "2%",
                                            width: "96%",
                                            marginTop: "1%",
                                            marginBottom: "1%",
                                        }}
                                    >
                                        <ListItemText primary={subpage} />
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    </div>
                ))}
            </List>
        </Drawer >
    );
};

export default DrawerComponent;
