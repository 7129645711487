import React from 'react';
import { Grid } from '@mui/material';
import { useDeviceType } from "../../components/deviceType";
import './Homepage.css';
import maskOne from "../../assets/maskOne.png";
import maskTwo from "../../assets/maskTwo.png";
const Assessment = () => {
    const deviceType = useDeviceType();
    return <>
        <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ padding: '5%', marginTop: deviceType != "desktop" ? "10%" : 0 }}>
            <Grid style={{ order: 1 }} justifyContent="center" alignItems="center" item xs={12} md={4}>
                <p style={styles.text1}>Assessment and Feedback System</p>
                <p style={{ ...styles.text2, marginBottom: deviceType != "desktop" ? "5%" : 0 }}>Understanding Beyond Right and Wrong</p>
            </Grid>
            <Grid style={{ order: 2, padding: '1%' }} justifyContent="center" alignItems="center" item xs={12} md={4}>
                <div style={styles.imageBox}>
                    <div style={{ ...styles.paper, backgroundColor: "#F5F3F3", width: '100%', }}>
                        <img src={maskOne} alt="students" style={{ ...styles.image, height: 'auto' }} />
                    </div>
                    <div>
                        <p style={styles.text3}>AI-Powered Evaluations</p>
                        <p style={styles.text4}>Our AI assesses student responses, offering detailed feedback that goes beyond simple correctness.</p>
                    </div>
                </div>
            </Grid>
            <Grid style={{ order: 3, padding: '1%' }} justifyContent="center" alignItems="center" item xs={12} md={4}>
                <div style={styles.imageBox}>
                    <div style={{ ...styles.paper, backgroundColor: "#F5F3F3", width: '100%', }}>
                        <img src={maskTwo} alt="students" style={{ ...styles.image, height: 'auto' }} />
                    </div>
                    <div>
                        <p style={styles.text3}>Personalized Improvement Plans</p>
                        <p style={styles.text4}>Receive suggestions for tailored learning materials to address individual challenges.</p>
                    </div>
                </div>
            </Grid>
        </Grid>
    </>
}
export default Assessment;

const styles = {
    box: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    text1: {
        fontSize: '1.05rem',
        fontWeight: '700',
        textAlign: "center"
    },
    text2: {
        fontSize: '1rem',
        fontWeight: '400',
        textAlign: "center"
    },
    text3: {
        marginTop: '1.8rem',
        fontSize: '0.9rem',
        fontWeight: '700',
        color: "#6321A6",
        textAlign: 'center',
    },
    text4: {
        fontSize: '0.7rem',
        fontWeight: '400',
        textAlign: 'center',
        marginBottom: '1.8rem',
    },
    paper: {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: 5,
    },
    image: {
        width: "100%",
        height: '30vh',
        display: 'block',
        margin: 'auto'
    },
    imageBox: {
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        borderColor: "#d3d3d3",
        padding: '2%',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    }
}