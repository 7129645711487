// RoundCheckbox.js

import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import SvgIcon from '@mui/material/SvgIcon';

const RoundCheckboxIcon = (props) => (
    <SvgIcon {...props}>
        <circle cx="12" cy="12" r="10" fill="#6321A6" stroke="black" strokeWidth="1" />
        <path d="M6.5 13.5l4 4 8-8" fill="white" strokeWidth="15" />
    </SvgIcon>
);

const RoundCheckedboxIcon = (props) => (
    <SvgIcon {...props}>
        <circle cx="12" cy="12" r="10" fill="white" stroke="black" strokeWidth="1" />
        <path d="M6.5 13.5l4 4 8-8" fill="#6321A6" strokeWidth="15" />
    </SvgIcon>
);


const RoundCheckbox = ({ checked, onChange }) => {
    const [checkboxValue, setCheckboxValue] = useState(checked);

    const handleCheckboxChange = () => {
        setCheckboxValue(!checkboxValue);
        if (onChange) {
            onChange(!checkboxValue);
        }
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checkboxValue}
                    onChange={handleCheckboxChange}
                    icon={<RoundCheckboxIcon />} // Icon for unchecked state
                    checkedIcon={<RoundCheckedboxIcon />} // Icon for checked state
                    sx={{
                        '& .MuiIconButton-root': {
                            width: '24px', // Adjusting the width of the checkbox
                            height: '24px', // Adjusting the height of the checkbox
                            padding: '0', // Removing padding to make the checkbox compact
                        },
                        '& .MuiIconButton-root:hover': {
                            backgroundColor: checkboxValue ? '#9C40F7' : '#f0f0f0', // Background color on hover
                        },
                    }}
                />
            }
        />
    );
};

export default RoundCheckbox;
