import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Box, } from '@mui/material';
import styled from 'styled-components';
import { WhiteButton } from '../../components/buttonStyles';
import landing from "../../assets/landing.png";
import { useDeviceType } from "../../components/deviceType";
import './Homepage.css';

const Banner = () => {
    const deviceType = useDeviceType();
    return <>
        <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ backgroundColor: '#F5EBFF', paddingTop: deviceType != "desktop" ? "15%" : "8%" }} >
            <Grid item xs={12} md={6} style={{ padding: '5%', paddingRight:'2%' }}>
                <StyledPaper elevation={3}>
                    <StyledTitle>
                        AI-based Tutoring
                        <br />
                        SaaS Platform
                        <br />
                        Solution Architecture
                    </StyledTitle>
                    <StyledText>
                        An outline of the comprehensive generative AI-based tutoring SaaS
                        platform, including user roles, AI model, content management system,
                        assessment and feedback system, multimodal learning, user interface,
                        security, analytics, and integration with external tools.
                    </StyledText>
                    <StyledBox>
                        <StyledLink to="/choosePlan">
                            <WhiteButton variant="contained" fullWidth>
                                Get Started
                            </WhiteButton>
                        </StyledLink>
                    </StyledBox>
                </StyledPaper>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: '5%' }}>
                <StyledPaper elevation={3}>
                    <img src={landing} alt="students" style={{ width: '100%' }} />
                </StyledPaper>
            </Grid>
        </Grid>
    </>
}

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width:  max-content;
  padding: 0;
  margin: 0;
`;

const StyledPaper = styled.div`
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:flex-start;
`;

const StyledTitle = styled.h1`
  font-size: 2rem;
  color: #252525;
  /* font-family: "Manrope"; */
  font-weight: bold;
  padding-top: 0;
  letter-spacing: normal;
  line-height: normal;
`;

const StyledText = styled.p`
  /* color: #550080; */
  margin-top: 30px;
  margin-bottom: 30px; 
  letter-spacing: normal;
  line-height: normal;
  padding-right: 20px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
export default Banner;