import axios from 'axios';

const url = `https://ixitutor.ixicities.com/api/`;

const instance = axios.create({
  baseURL: url,
  timeout: 10000,
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response?.status == 404) {
      console.log(error?.response?.data, 404)
      // toast.show(error.response?.data?.message, {
      //   type: 'danger',
      //   placement: 'bottom',
      //   duration: 3000,
      //   offset: 30,
      //   animationType: 'zoom-in',
      // });
      return error?.response;
    } else if (error.response?.status == 400 && error.response?.data?.message) {
      console.log(error?.response?.data, 400)
      // toast.show(error.response?.data?.message, {
      //   type: 'danger',
      //   placement: 'bottom',
      //   duration: 3000,
      //   offset: 30,
      //   animationType: 'zoom-in',
      // });
      return error?.response;
    } else {
      console.log(error)
      // toast.show('Something went wrong', {
      //   type: 'danger',
      //   placement: 'bottom',
      //   duration: 3000,
      //   offset: 30,
      //   animationType: 'zoom-in',
      // });
      return error?.response;
    }
  },
);

instance.interceptors.request.use(async request => {
  const isExist = localStorage.getItem('accessToken');
  if (isExist) {
    request.headers.Authorization = `Bearer ${isExist}`;
  }
  return request;
});

export const uploadFile = async (payload) => {


  var formdata = new FormData();
  formdata.append("singleFile", payload);

  axios({
    method: "post",
    url: `${url}/Common/Upload`,
    data: formdata,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
      //handle success
      return response
    })
    .catch(function (e) {
      //handle error
      return e
    });




}

export default instance;
