import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addStuff } from "../../redux/userRelated/userHandle";
import { underControl } from "../../redux/userRelated/userSlice";
import Popup from "../../components/Popup";
import axios from "../../utils/axios";
import axiosIn from "axios";

import styledCom from "styled-components";
import { getSubjectList } from "../../redux/sclassRelated/sclassHandle";
import { toast } from "react-toastify";

const PlanForm = () => {
  const [subjects, setSubjects] = useState([
    {
      name: "",
      price: "",
      noOfTeachers: "",
      noOfStudents: "",
      noOfParents: "",
      noOfDocumentSubmission: "Unlimited",
      noOfChats: "Unlimited",
      description: "",
    },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const userState = useSelector((state) => state.user);
  const { status, currentUser, response, error } = userState;
  const [className, setClassName] = useState("");
  const [sclassName, setSclassName] = useState("");
  const [classId, setClassId] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);

  // const sclassName = params.id
  const adminID = currentUser._id;
  const address = "Plan";

  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubjectNameChange = (index) => (event) => {
    const newSubjects = [...subjects];
    newSubjects[index].name = event.target.value;
    setSubjects(newSubjects);
  };

  const handleSubjectDescriptionChange = (index) => (event) => {
    const newSubjects = [...subjects];
    newSubjects[index].description = event.target.value;
    setSubjects(newSubjects);
  };
  const handleSubjectPriceChange = (index) => (event) => {
    const newSubjects = [...subjects];
    newSubjects[index].price = event.target.value;
    setSubjects(newSubjects);
  };
  const handleSubjectNoOfParentsChange = (index) => (event) => {
    const newSubjects = [...subjects];
    newSubjects[index].noOfParents = event.target.value;
    setSubjects(newSubjects);
  };
  const handleSubjectNoOfTeachersChange = (index) => (event) => {
    const newSubjects = [...subjects];
    newSubjects[index].noOfTeachers = event.target.value;
    setSubjects(newSubjects);
  };
  const handleSubjectNoOfStudentsChange = (index) => (event) => {
    const newSubjects = [...subjects];
    newSubjects[index].noOfStudents = event.target.value;
    setSubjects(newSubjects);
  };

  const fields = {
    
    name: subjects[0]?.name,
    price: subjects[0]?.price,
    noOfTeachers: subjects[0]?.noOfTeachers,
    noOfStudents: subjects[0]?.noOfStudents,
    noOfParents: subjects[0]?.noOfParents,
    noOfDocumentSubmission: "Unlimited",
    noOfChats: "Unlimited",
    description: subjects[0]?.description,
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setLoader(true);
    dispatch(addStuff(fields, address));
    setLoader(false);
    return;

    // toast.error("Something went wrong while uploading document")
    // setLoader(false)
  };

  useEffect(() => {
    if (status === "added") {
      navigate(-1);
      dispatch(underControl());
      setLoader(false);
    } else if (status === "failed") {
      setMessage(response);
      setShowPopup(true);
      setLoader(false);
    } else if (status === "error") {
      setMessage("Network Error");
      setShowPopup(true);
      setLoader(false);
    }
  }, [status, navigate, error, response, dispatch]);

  return (
    <StyledContainer>
      <StyledBox>
        <form onSubmit={submitHandler}>
          <Stack
            sx={{
              alignItems: "center",
              mb: 3,
            }}
          >
            <span style={{ fontSize: "20px", fontWeight: "500" }}>
              Add Plan
            </span>
          </Stack>
          {subjects.map((subject, index) => (
            <Stack spacing={2} key={index}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Plan Name"
                  variant="outlined"
                  value={subject.name}
                  onChange={handleSubjectNameChange(index)}
                  sx={styles.inputField}
                  required
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="price"
                  variant="outlined"
                  value={subject.price}
                  onChange={handleSubjectPriceChange(index)}
                  sx={styles.inputField}
                  required
                  type="Number"
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Description"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={subject.description}
                  onChange={handleSubjectDescriptionChange(index)}
                  sx={styles.inputField}
                  required
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Number of parents"
                  variant="outlined"
                  value={subject.noOfParents}
                  onChange={handleSubjectNoOfParentsChange(index)}
                  sx={styles.inputField}
                  required
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Number of students"
                  variant="outlined"
                  value={subject.noOfStudents}
                  onChange={handleSubjectNoOfStudentsChange(index)}
                  sx={styles.inputField}
                  required
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Number of teachers"
                  variant="outlined"
                  value={subject.noOfTeachers}
                  onChange={handleSubjectNoOfTeachersChange(index)}
                  sx={styles.inputField}
                  required
                />
              </Grid>
            </Stack>
          ))}
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <Box display="flex" justifyContent="flex-start">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </Box>
          </Grid>
          <Popup
            message={message}
            setShowPopup={setShowPopup}
            showPopup={showPopup}
          />
        </form>
      </StyledBox>
    </StyledContainer>
  );
};

export default PlanForm;

const styles = {
  inputField: {
    "& .MuiInputLabel-root": {
      color: "#838080",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#838080",
    },
  },
};

const StyledContainer = styledCom(Box)`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledBox = styledCom(Box)`
  width: 600px;
  padding: 50px 3rem 50px;
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
`;
