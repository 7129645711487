import React, { useEffect, useState } from "react";
import {
  getClassStudents,
  getSubjectDetails,
  getTopicDetails,
} from "../../redux/sclassRelated/sclassHandle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Tab,
  Container,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Button,
  Stack,
} from "@mui/material";
import {
  BlueButton,
  GreenButton,
  PurpleButton,
} from "../../components/buttonStyles";
import styled from "styled-components";
import { toast } from "react-toastify";
import axios from "../../utils/axios";

const ViewSchoolDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [schoolDetails, setSchoolDetails] = useState(null);

  const { id } = params;

  const getDashboardData = async () => {
    setLoading(true);
    const { data, status } = await axios.get("SuperAdmin/school/" + id);
    if (status == 200) {
      setSchoolDetails(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    getDashboardData();
  }, []);

  const approveAccount = async () => {
    setLoading(true);
    const { data, status } = await axios.patch("SuperAdmin/school/" + id,{status:"APPROVED"});
    if (status == 200) {
      getDashboardData();
    }
    setLoading(false);
  }

  console.log(schoolDetails);

  const SubjectDetailsSection = () => {
    return (
      <>
        <StyledContainer>
          <StyledBox>
            <Stack
              sx={{
                alignItems: "center",
                mb: 3,
              }}
            >
              <span style={{ fontSize: "20px", fontWeight: "500" }}>
                School Details
              </span>
            </Stack>
            <Stack spacing={2}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ width: "250px" }}>
                  <span style={{ fontSize: "16px" }}>School Name</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px" }}>
                    : {schoolDetails?.schoolName}
                  </span>
                </div>
              </div>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ width: "250px" }}>
                  <span style={{ fontSize: "16px" }}>Username</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px" }}>
                    : {schoolDetails?.name}
                  </span>
                </div>
              </div>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ width: "250px" }}>
                  <span style={{ fontSize: "16px" }}>Email</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px" }}>
                    : {schoolDetails?.email}
                  </span>
                </div>
              </div>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ width: "250px" }}>
                  <span style={{ fontSize: "16px" }}>Kyc Card</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px" }}>
                    : {schoolDetails?.kycCard}
                  </span>
                </div>
              </div>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ width: "250px" }}>
                  <span style={{ fontSize: "16px" }}>KYC ID Number</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px" }}>
                    : {schoolDetails?.kycIDNumber}
                  </span>
                </div>
              </div>

              <div style={{ flexDirection: "row", display: "flex" ,alignItems:'center'}}>
                <div style={{ width: "250px" }}>
                  <span style={{ fontSize: "16px" }}>Card Front Image</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px",display:'flex',alignItems:'center' }}>
                    :{' '} <img src={schoolDetails?.frontImage?.Location}  style={{width:70,height:70,borderRadius:10,marginLeft:7}}/>
                  </span>
                </div>
              </div>

              <div style={{ flexDirection: "row", display: "flex" ,alignItems:'center'}}>
                <div style={{ width: "250px" }}>
                  <span style={{ fontSize: "16px" }}>Card Back Image</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px",display:'flex',alignItems:'center'  }}>
                    : <img src={schoolDetails?.backImage?.Location}  style={{width:70,height:70,borderRadius:10,marginLeft:7}}/>
                  </span>
                </div>
              </div>

              {/* <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>collectionId</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {schoolDetails?.collectionId}</span>
                </div>
              </div> */}
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ width: "250px" }}>
                  <span style={{ fontSize: "16px" }}>status</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px" }}>
                    : {schoolDetails?.status}
                  </span>
                </div>
              </div>
              {/* {!location.pathname.startsWith('/Student') && <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Topic Document</span>
                </div>
                <div>
                  : <GreenButton variant="contained"
                    style={{ marginLeft: '0px' }}
               
                     >
                    Download Document
                  </GreenButton>
                </div>

              </div>} */}
            </Stack>
          {schoolDetails?.status == "PENDINGAPPROVAL" &&  <div style={{ marginTop: 20 ,alignItems:'center',display:'flex',justifyContent:'center'}} >
            <GreenButton variant="contained" style={{ marginLeft: "0px" }} onClick={approveAccount}>
                APPROVE ACCOUNT
              </GreenButton>
            </div>}
            <div  style={{ marginTop: 20 }}>
              <GreenButton variant="contained" style={{ marginLeft: "0px" }} onClick={() => {
                localStorage.setItem("SchoolId",schoolDetails?._id)
                navigate("/SuperAdmin/classes")
              }}>
                Classes
              </GreenButton>
              <GreenButton variant="contained" style={{ marginLeft: "10px" }} onClick={() => {
                localStorage.setItem("SchoolId",schoolDetails?._id)
                navigate("/SuperAdmin/students")
              }}>
                Students
              </GreenButton>
              <GreenButton variant="contained" style={{ marginLeft: "10px" }} onClick={() => {
                navigate("/SuperAdmin/subjects")
              }}>
                Subjects
              </GreenButton>
              <GreenButton variant="contained" style={{ marginLeft: "10px" }} onClick={() => {
                navigate("/SuperAdmin/teachers")
              }}>
                Teachers
              </GreenButton>
              <GreenButton variant="contained" style={{ marginTop: "10px" }} onClick={() => {
                navigate("/SuperAdmin/topics")
              }}>
                Topics
              </GreenButton>
            </div>

            <Button
              style={{ marginTop: 20 }}
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              Go Back
            </Button>
          </StyledBox>
        </StyledContainer>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <div> Loading...</div>
      ) : (
        <>
          <SubjectDetailsSection />
        </>
      )}
    </>
  );
};

export default ViewSchoolDetails;

const StyledContainer = styled(Box)`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledBox = styled(Box)`
  width: 600px;
  padding: 50px 3rem 50px;
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
`;
