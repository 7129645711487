import React, { useEffect, useState } from "react";
import { Button, TextField, Grid, Box, Typography, CircularProgress, Stack } from "@mui/material";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addStuff } from '../../redux/userRelated/userHandle';
import { underControl } from '../../redux/userRelated/userSlice';
import Popup from '../../components/Popup';
import axios from "../../utils/axios";
import axiosIn from "axios";

import styledCom from "styled-components";
import { getSubjectList } from "../../redux/sclassRelated/sclassHandle";
import { toast } from "react-toastify";
import sendMessage from '../../assets/send-message.png'
import cameraImage from '../../assets/camera.png'
import moment from "moment";


const Interactive = () => {

    const params = useParams()
    const location = useLocation()
    const userState = useSelector(state => state.user);
    const { status, currentUser, response, error } = userState;
    const [loading, setLoading] = useState(false)
    const stateData = location.state;
    const [message, setMessage] = useState("");

    const [chatData, setChatData] = useState([
        {
            "role": "user",
            "content": "hi"
        },
        { role: "user", content: stateData?.topicName },
    ]);

    const getData = async (incomingData = null) => {
        setLoading(true)
        try {
            const { data, status } = await axios.post("https://lms.ixicities.com/api/v1/lms/chat/", {
                "messages": incomingData ? [...chatData, incomingData]?.filter(item => item?.role != "image") : chatData?.filter(item => item?.role != "image"),
                "collection_id": currentUser?.school?.collectionId
            })
            if (status == 200) {
                // (mess + data).replaceAll("data: ", "").replaceAll("data:", "").replaceAll("[DONE]", "").replaceAll("\n", "")
                if (incomingData) {
                    setChatData([...chatData, incomingData, { role: "assistant", content: data?.response }])
                    setLoading(false)
                    return
                }
                setChatData([...chatData, { role: "assistant", content: data?.response }])
            }
            setLoading(false)
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }


    const uploadImage = async (file) => {

        setChatData([...chatData, { role: "image", content: URL.createObjectURL(file) }])
        var formdata = new FormData();
        formdata.append('image', file);
        formdata.append("collection_id", currentUser?.school?.collectionId)
        formdata.append("message", "can you elaborate it?")
        setLoading(true)
        const { data, status } = await axios.post('https://lms.ixicities.com/api/v1/lms/upload-image/', formdata, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (status == 200) {
            setChatData(prev => [...prev, { role: "assistant", content: data?.response }])
            console.log(data)
        } else {
            toast.show(`Please capture again`, {
                type: 'danger',
            });
        }
        setLoading(false)
    }


    useEffect(() => {
        getData()
    }, [])

    return (
        <StyledContainer>
            <StyledBox>
                {
                    chatData?.map((item, index) => {
                        return item?.content != "hi" && <div style={{
                            maxWidth: '50%',
                            borderColor: "rgba(227, 227, 227, 0.81)",
                            borderWidth: 1,
                            marginTop: index == 0 ? 0 : 20,
                            marginLeft: item?.role == "user" ? 0 : 10,
                            marginRight: item?.role == "user" || item?.role == "image" ? 10 : 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 7,
                            paddingBottom: 7,
                            borderRadius: 15,
                            float: item?.role == "user" || item?.role == "image" ? 'right' : 'left',
                            alignSelf: item?.role == "user" || item?.role == "image" ? "flex-end" : "flex-start",
                            backgroundColor: item?.role == "user" ? "rgba(123, 20, 225, 1)" : "rgba(245, 245, 247, 1)"
                        }}>
                            {item?.role == "user" && <GText color={item?.role == "user" ? "white" : '#595959'} size={14}>{item?.content}</GText>}
                            {item?.role == "assistant" && <GText color={'#595959'} size={14}>{item?.content}</GText>}
                            {item?.role == "image" && <img src={item?.content} width={100} height={100} />}
                        </div>
                    })}

                {
                    loading && <>
                        <div
                            style={{
                                marginTop: 10,
                                marginRight: 0,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 7,
                                borderRadius: 15,
                                alignSelf: "flex-start",
                            }}
                        >
                            {/* <Tick /> */}
                            <GText mleft={10} color={'black'} size={14}>Searching for: {stateData?.topicName}</GText>
                        </div>

                        <div style={{
                            marginRight: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 7,
                            borderRadius: 15,
                            alignSelf: "flex-start",
                        }}>
                            {/* <Tick /> */}
                            <GText mleft={10} color={'black'} size={14}>{"Generating answer for you..."}</GText>
                        </div>
                    </>
                }



            </StyledBox>
            
                <div style={{
                    display: 'flex',
                    position: 'absolute',
                    bottom: '20px',
                    alignSelf: 'center',
                    left: '45%',
                    width: 400,
                    height: 38,
                    borderRadius: 20,
                    borderWidth: 1, borderColor: "rgba(123, 20, 225, 1)",
                    paddingLeft: 10,
                    border: '1px solid rgb(123, 20, 225)',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    justifyContent: 'space-between',
                    paddingRight: 7
                }}>
                    <input placeholder="Ask me anything ...." style={{
                        border: 'none',
                        fontSize: 12,
                        width: '320px'
                    }}
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value)
                        }}

                    />

                    <label for="file-input">
                        <img onClick={() => {
                            // setChatData([...chatData, { role: "user", content: message },])
                            // getData({ role: "user", content: message });
                            // setMessage("");
                        }} src={cameraImage} style={{ height: 22, width: 22, alignSelf: 'center', marginTop: 5 }} />
                    </label>
                    <input
                        id="file-input"
                        type="file"
                        hidden
                        accept="image"
                        onChange={(e) => {
                            uploadImage(e?.target?.files[0])
                            // uploadFile(e?.target?.files[0], index)
                        }}
                    />


                    <img onClick={() => {
                        setChatData([...chatData, { role: "user", content: message },])
                        getData({ role: "user", content: message });
                        setMessage("");
                    }} src={sendMessage} style={{ height: 22, width: 22, alignSelf: 'center' }} />
                </div>
         

        </StyledContainer>
    );
}

export default Interactive;

const styles = {
    inputField: {
        '& .MuiInputLabel-root': {
            color: '#838080',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#838080',
        },
    },
};

const StyledContainer = styledCom(Box)`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  justify-content: center;
  height:90%;
  margin-top: 10px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 96%;
  margin-left: 2%;
`;

const StyledBox = styledCom(Box)`
  width: 99%;
  height:85%;
  
  display:flex;
  flex-direction:column;
  margin-bottom: 10px;
  overflow:auto;
  position:relative;

`;

const GText = styledCom.span`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props?.isDarkTheme ? 'white' : props.color};
  ${(props) => (props.weight ? `font-weight : ${props.weight}` : null)}
   ${(props) => (props.family ? `font-family : ${props.family}` : null)}
   ${(props) => (props.talign ? `text-align : ${props.talign}` : null)}
   ${(props) => (props.mleft ? `margin-left : ${props.mleft}px` : null)}
   ${(props) => (props.mright ? `margin-right : ${props.mright}px` : null)}
   ${(props) => (props.mtop ? `margin-top : ${props.mtop}px` : null)}
   ${(props) => (props.mbottom ? `margin-bottom : ${props.mbottom}px` : null)}
   ${(props) => (props.padding ? `padding : ${props.padding}px` : null)}
   ${(props) => (props.pleft ? `padding-left : ${props.pleft}px` : null)}
  ${(props) => (props.pright ? `padding-right : ${props.pright}px` : null)} 
  ${(props) => (props.ptop ? `padding-top : ${props.ptop}px` : null)}
   ${(props) => (props.pbottom ? `padding-bottom : ${props.pbottom}px` : null)}
   ${(props) =>
        props.underline ? `text-decoration-line : ${props.underline}` : null}
   ${(props) =>
        props.underline ? `text-decoration-line : ${props.underline}` : null}
   ${(props) => (props.position ? `position : ${props.position}` : null)}  
   ${(props) => (props.top ? `top : ${props.top}px` : null)}  
   ${(props) => (props.left ? `left : ${props.left}px` : null)}  
   ${(props) => (props.right ? `right : ${props.right}px` : null)}  
   ${(props) => (props.bottom ? `bottom : ${props.bottom}px` : null)}  
   ${(props) => (props.zindex ? `z-index : ${props.zindex}` : null)}
   ${(props) => (props.width ? `width : ${props.width}px` : null)}
   ${(props) => (props.letterSpacing ? `letter-spacing : ${props.letterSpacing}px` : null)}

`;

