import React, { useEffect, useState } from 'react'
import { getClassStudents, getSubjectDetails, getTopicDetails } from '../../redux/sclassRelated/sclassHandle';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab, Container, Typography, BottomNavigation, BottomNavigationAction, Paper, Button, Stack } from '@mui/material';
import { BlueButton, GreenButton, PurpleButton } from '../../components/buttonStyles';
import styled from "styled-components";
import { toast } from 'react-toastify';
import axios from "../../utils/axios";


const ViewPlan = () => {
  const navigate = useNavigate()
  const params = useParams()

  const location = useLocation();
  const [planDetails,setPlanDetails] = useState(null)
  const { subloading, subjectDetails, topicDetails, sclassStudents, getresponse, error } = useSelector((state) => state.sclass);

  const { id } = params

  const getPlanById = async () => {
    const {data,status} = await axios.get(`PlanView/${id}`);
    if(status == 200){
      setPlanDetails(data)
    }
  } 

  useEffect(() => {
    getPlanById()
  }, []);

  if (error) {
    console.log(error)
  }
  console.log(topicDetails)

  const SubjectDetailsSection = () => {

    return (
      <>
        <StyledContainer>
          <StyledBox>
            <Stack sx={{
              alignItems: 'center',
              mb: 3
            }}>
              <span style={{ fontSize: '20px', fontWeight: '500' }}>Plan Details</span>

            </Stack>
            <Stack spacing={2}>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Plan Name</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {planDetails?.name}</span>
                </div>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Price</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {planDetails?.price}</span>
                </div>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Description</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {planDetails?.description}</span>
                </div>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Number of Students</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {planDetails?.noOfStudents}</span>
                </div>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Number of Parents</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {planDetails?.noOfParents}</span>
                </div>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '250px' }}>
                  <span style={{ fontSize: '16px', }}>Number of Teachers</span>
                </div>
                <div>
                  <span style={{ fontSize: '16px', }}>: {planDetails?.noOfTeachers}</span>
                </div>
              </div>
            </Stack>
            <Button style={{ marginTop: 20 }} variant="outlined" onClick={() => navigate(-1)}>
              Go Back
            </Button>
          </StyledBox>
        </StyledContainer>
      </>
    );
  }

  return (
    <>
      {subloading ?
        < div > Loading...</div >
        :
        <>

          <SubjectDetailsSection />

        </>
      }
    </>
  )
}

export default ViewPlan


const StyledContainer = styled(Box)`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledBox = styled(Box)`
  width: 600px;
  padding: 50px 3rem 50px;
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
`;