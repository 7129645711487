import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getSubjectList,
  getTopicsList,
} from "../../redux/sclassRelated/sclassHandle";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Paper, Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TableTemplate from "../../components/TableTemplate";
import { BlueButton, GreenButton } from "../../components/buttonStyles";
import Popup from "../../components/Popup";
import axios from "../../utils/axios";

const Plans = () => {
  const navigate = useNavigate();
    const [loading,setLoading] = useState(false)
  const [plansList,setPlansList] = useState(null)
  useEffect(() => {
    getPlans()
  }, []);

  const getPlans = async () => {
    const {data,status} = await axios.get("PlanList");
    if(status == 200){
        setPlansList(data)
    }
  }


  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");

  const subjectColumns = [
    { id: "name", label: "Plan Name" },
    { id: "price", label: "Price" },
    { id: "description", label: "Description" },
    { id: "noOfTeachers", label: "No Of Teachers" },
    { id: "noOfStudents", label: "No Of Students" },
    { id: "noOfParents", label: "No Of parents" },
  ];

  const subjectRows = plansList?.map((subject) => {
    return {
        "name": subject?.name,
        "price": subject?.price,
        "description": subject?.description,
        "noOfTeachers": subject?.noOfTeachers,
        "noOfStudents": subject?.noOfStudents,
        "noOfParents": subject?.noOfParents,
        id: subject._id,
    };
  });

  const deletePlan = async (id) => {
    const {data,status} = await axios.delete(`Plan/${id}`);
    if(status == 204 || status == 200){
        getPlans()
    }
  }

  const SubjectsButtonHaver = ({ row }) => {
    return (
      <>
        <IconButton onClick={() => {deletePlan(row?.id)}}>
          <DeleteIcon color="error" />
        </IconButton>
        <BlueButton
          variant="contained"
          onClick={() => navigate(`/SuperAdmin/plans/view/${row.id}`)}
        >
          View
        </BlueButton>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
              marginBottom: "16px",
            }}
          >
            <GreenButton
              variant="contained"
              onClick={() => navigate("/SuperAdmin/plans/addPlan")}
            >
              Add Plan
            </GreenButton>
          </Box>
          {
            // response ?
            //     <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
            //         <GreenButton variant="contained"
            //             onClick={() => navigate("/Admin/topic/choosesubject")}>
            //             Add Topic
            //         </GreenButton>
            //     </Box>
            //     :
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              {Array.isArray(plansList) && plansList.length > 0 && (
                <TableTemplate
                  buttonHaver={SubjectsButtonHaver}
                  columns={subjectColumns}
                  rows={subjectRows}
                />
              )}
              {/* <SpeedDialTemplate actions={actions} /> */}
            </Paper>
          }
        </>
      )}
      <Popup
        message={message}
        setShowPopup={setShowPopup}
        showPopup={showPopup}
      />
    </>
  );
};

export default Plans;
