import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getSubjectList, getTopicsList, getTopicsListAdmin } from '../../../redux/sclassRelated/sclassHandle';
import { deleteUser } from '../../../redux/userRelated/userHandle';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {
    Paper, Box, IconButton,
} from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import TableTemplate from '../../../components/TableTemplate';
import { BlueButton, GreenButton } from '../../../components/buttonStyles';
import SpeedDialTemplate from '../../../components/SpeedDialTemplate';
import Popup from '../../../components/Popup';

const ShowTopics = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { topicsList, loading, error, response } = useSelector((state) => state.sclass);
    const { currentUser, currentRole } = useSelector((state) => state.user);
    const isSuperAdmin = currentRole == "SuperAdmin";
    const adminID =
      currentRole == "SuperAdmin"
        ? localStorage.getItem("SchoolId")
        : currentUser._id;

    useEffect(() => {
        if(isSuperAdmin){ dispatch(getTopicsListAdmin(adminID, "topics")); return;}
        dispatch(getTopicsList(adminID, "topics"));
    }, [currentUser._id, dispatch]);

    if (error) {
        console.log(error);
    }

    const [showPopup, setShowPopup] = useState(false);
    const [message, setMessage] = useState("");

    const deleteHandler = (deleteID, address) => {
        console.log(deleteID);
        console.log(address);
        setMessage("Sorry the delete function has been disabled for now.")
        setShowPopup(true)

        // dispatch(deleteUser(deleteID, address))
        //     .then(() => {
        //         dispatch(getSubjectList(currentUser._id, "AllSubjects"));
        //     })
    }

    const subjectColumns = [
        { id: 'topicName', label: 'Topic Name', },
        { id: 'topicCode', label: 'Topic Code', },
        { id: 'topicDescription', label: 'Topic Description', },
        { id: 'topicSubTitle', label: 'Topic Subtitle', },

        { id: 'subject', label: 'Subject' },
        { id: 'school', label: 'School' },


        // { id: 'sclassName', label: 'Class', minWidth: 170 },
    ]

    const subjectRows = topicsList.map((subject) => {
        return {
            topicName: subject.topicName,
            topicCode: subject.topicCode,
            topicCode: subject.topicCode,
            topicDescription: subject.topicDescription,
            topicSubTitle: subject.topicSubTitle,
            subject: subject.subject?.subName,
            school: subject.school?.name,
            id: subject._id,
        };
    })

    const SubjectsButtonHaver = ({ row }) => {
        return (
            <>
                <IconButton onClick={() => deleteHandler(row.id, "Subject")}>
                    <DeleteIcon color="error" />
                </IconButton>
                <BlueButton variant="contained"
                    onClick={() => {
                        if(currentRole == "SuperAdmin"){
                            navigate(`/SuperAdmin/topics/topic/${row.id}`)
                            return
                        }
                        navigate(`/Admin/topics/topic/${row.id}`)}}>
                    View
                </BlueButton>
            </>
        );
    };

    const actions = [
        {
            icon: <PostAddIcon color="primary" />, name: 'Add New Topic',
            action: () => navigate("/Admin/topic/choosesubject")
        },
        // {
        //     icon: <DeleteIcon color="error" />, name: 'Delete All Subjects',
        //     action: () => deleteHandler(currentUser._id, "Subjects")
        // }
    ];

    return (
        <>
            {loading ?
                <div>Loading...</div>
                :
                <>
                    {!isSuperAdmin && <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginBottom: '16px' }}>
                        <GreenButton variant="contained"
                            onClick={() => navigate("/Admin/addtopic/subject")}>
                            Add Topic
                        </GreenButton>
                    </Box>}
                    {
                        // response ?
                        //     <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        //         <GreenButton variant="contained"
                        //             onClick={() => navigate("/Admin/topic/choosesubject")}>
                        //             Add Topic
                        //         </GreenButton>
                        //     </Box>
                        //     :
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            {Array.isArray(topicsList) && topicsList.length > 0 &&
                                <TableTemplate buttonHaver={SubjectsButtonHaver} columns={subjectColumns} rows={subjectRows} />
                            }
                            {/* <SpeedDialTemplate actions={actions} /> */}
                        </Paper>
                    }
                </>
            }
            <Popup message={message} setShowPopup={setShowPopup} showPopup={showPopup} />

        </>
    );
};

export default ShowTopics;