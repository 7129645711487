import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, MobileStepper, Box } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import student from "../../assets/student.png";
import parent from "../../assets/parent.png";
import teacher from "../../assets/teacher.png";
import styled from 'styled-components';
import { useDeviceType } from "../../components/deviceType";
import './Homepage.css';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        index: 1,
        label: 'Student',
        imgPath: student,
        text: 'Access course content, receive AI-generated lessons and assessments, submit answers through text, handwriting, and oral input.'
    },
    {
        index: 2,
        label: 'Teacher',
        imgPath: teacher,
        text: "Upload course content, monitor and analyze student performance, customize teaching methods based on AI generated analytics."
    },
    {
        index: 3,
        label: 'Parent',
        imgPath: parent,
        text: "View student performance and analytics, receive updates on progress."
    },
];
function Carousel() {
    const theme = useTheme();
    const deviceType = useDeviceType();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (steps) => {
        setActiveStep(steps);
    };

    return (
        <Box sx={{ flexGrow: 1, marginTop: deviceType != "desktop" ? "10%" : "5%" }}>
            <h2 style={{ marginBottom: 20, marginLeft: deviceType == "mobile" ? '5vw' : '3vw' }}>User Roles</h2>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                    <div key={step.index} style={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: 'center',
                    }}>
                        {deviceType != "mobile" && <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: 'center',
                            borderWidth: 1,
                            borderRadius: 10,
                            borderStyle: 'solid',
                            borderColor: '#ccc',
                            marginLeft: '3vw',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            width: deviceType == "desktop" ? '30vw' : '45vw',
                        }}>
                            <div style={styles.image}>
                                <Box
                                    component="img"
                                    sx={{
                                        // height: ,
                                        display: 'block',
                                        maxWidth: 150,
                                        overflow: 'hidden',
                                        width: '100%',
                                        borderWidth: 1,
                                        borderRadius: 10,
                                    }}
                                    src={images[(index - 1) == -1 ? 2 : index - 1]?.imgPath}
                                    alt={""}
                                />
                            </div>
                            <div style={{ width: "70%", alignItems: "center" }}>
                                <StyledPaper elevation={3}>
                                    <StyledTitle>{images[(index - 1) == -1 ? 2 : index - 1]?.label}</StyledTitle>
                                    <StyledText><p style={{ fontSize: '0.8rem' }}>{images[(index - 1) == -1 ? 2 : index - 1]?.text}</p></StyledText>
                                </StyledPaper>
                            </div>
                        </div>}
                        {deviceType !== "tablet" && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    borderStyle: 'solid',
                                    borderColor: '#ccc',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                    marginLeft: deviceType == "mobile" ? '5vw' : '0vw',
                                    width: deviceType === "desktop" ? '30vw' : '90vw',
                                }}
                            >
                                <div style={styles.image}>
                                    <Box
                                        component="img"
                                        sx={{
                                            // height: ,
                                            display: 'block',
                                            maxWidth: 150,
                                            overflow: 'hidden',
                                            width: '100%',
                                            borderWidth: 1,
                                            borderRadius: 10,
                                        }}
                                        src={step.imgPath}
                                        alt={""}
                                    />
                                </div>
                                <div style={{ width: "70%", alignItems: "center" }}>
                                    <StyledPaper elevation={3}>
                                        <StyledTitle>{step.label}</StyledTitle>
                                        <StyledText><p style={{ fontSize: '0.8rem' }}>{step.text}</p></StyledText>
                                    </StyledPaper>
                                </div>
                            </div>
                        )}
                        {deviceType != "mobile" && <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: 'center',
                            borderWidth: 1,
                            borderRadius: 10,
                            borderStyle: 'solid',
                            borderColor: '#ccc',
                            marginRight: '3vw',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            width: deviceType == "desktop" ? '30vw' : '45vw',
                        }}>
                            <div style={styles.image}>
                                <Box
                                    component="img"
                                    sx={{
                                        // height: ,
                                        display: 'block',
                                        maxWidth: 150,
                                        overflow: 'hidden',
                                        width: '100%',
                                        borderWidth: 1,
                                        borderRadius: 10,
                                    }}
                                    src={images[(index + 1) == 3 ? 0 : index + 1]?.imgPath}
                                    alt={""}
                                />
                            </div>
                            <div style={{ width: "70%", alignItems: "center", }}>
                                <StyledPaper elevation={3}>
                                    <StyledTitle>{images[(index + 1) == 3 ? 0 : index + 1]?.label}</StyledTitle>
                                    <StyledText><p style={{ fontSize: '0.8rem' }}>{images[(index + 1) == 3 ? 0 : index + 1]?.text}</p></StyledText>
                                </StyledPaper>
                            </div>
                        </div>
                        }
                    </div>
                ))}
            </AutoPlaySwipeableViews>

            <div style={{ width: '90vw', marginLeft: "5vw", marginRight: "5vw" }}>

                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            </div>
        </Box >
    );
}

const StyledTitle = styled.h6`
  font-size: 1.1rem;
  color: #252525;
`;

const StyledText = styled.p`
  font-size: 0.8rem;
`;

const StyledPaper = styled.div`
margin-right: 0.2rem;
margin-left: 0.5rem;
`;
const styles = {
    image: { width: "28%", marginLeft: "2%", marginTop: 30, marginBottom: 30 }
}

export default Carousel;