import React from "react";
import './Homepage.css';
import { Grid } from '@mui/material';
import { useDeviceType } from "../../components/deviceType";
import RoundCheckbox from "../../components/RoundCheckbox";
const BottomScreen = () => {
    const deviceType = useDeviceType();

    return (
        <>
            {/* <div className="background-div" style={{ marginTop: deviceType != "desktop" ? "15%" : "5%" }} > */}
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{
                padding: '5%',
                marginTop: '5%',
                backgroundColor: "#E3E9FA"
            }}>
                <Grid style={{ order: 1, paddingLeft: '5%' }} item xs={12} md={6}>
                    <p style={styles.text}> Content Management </p>
                    <p style={styles.text}> System (CMS) – Streamlining </p>
                    <p style={styles.text}> Educational Content {deviceType}</p>
                </Grid>
                <Grid style={{ order: 2, marginTop: deviceType != "desktop" ? "10%" : "0%", paddingRight: '5%' }} item xs={12} md={6}>
                    <div style={styles.flexRowBox}>
                        <RoundCheckbox checked={false} />
                        <p style={styles.text1}>Functionalities</p>
                    </div>
                    <div style={{ marginLeft: '8%' }}>
                        <p style={styles.text2}>Schools and educators can easily manage and upload course content, ensuring a seamless integration into our platform.</p>
                    </div>

                    <div style={{ ...styles.flexRowBox, marginTop: '8%' }}>
                        <RoundCheckbox checked={false} />
                        <p style={styles.text1}>AI Integration:</p>
                    </div>

                    <div style={{ marginLeft: '10%' }}>
                        <p style={styles.text2}>Automatically enhance your teaching material with AI-generated content, providing a richer learning experience.</p>
                    </div>
                </Grid>

            </Grid >
            {/* </div > */}
        </>
    );
}

export default BottomScreen;

const styles = {
    flexRowBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingLeft: 10,
        marginBottom: 2
    },
    text1: {
        fontSize: '1.4rem',
        color: '#232323',
    },
    text2: {
        fontSize: '0.9rem',
        color: '#232323',
    },
    text: {
        fontSize: '1.8rem',
        color: '#252525',
        fontWeight: 'bold',
        paddingTop: 0,
    }
}