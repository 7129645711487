import React, { useEffect, useState } from "react";
import { Button, TextField, Grid, Box, Typography, CircularProgress, Stack } from "@mui/material";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addStuff } from '../../redux/userRelated/userHandle';
import { underControl } from '../../redux/userRelated/userSlice';
import Popup from '../../components/Popup';
import axios from "../../utils/axios";
import axiosIn from "axios";

import styledCom from "styled-components";
import { getSubjectList } from "../../redux/sclassRelated/sclassHandle";
import { toast } from "react-toastify";


const Freeflow = () => {

    const params = useParams()
    const location = useLocation()
    const userState = useSelector(state => state.user);
    const { status, currentUser, response, error } = userState;
    const [loading, setLoading] = useState(false)
    const stateData = location.state;
    const [chatData, setChatData] = useState([
        { name: "user", message: stateData?.topicName },
    ])

    const getData = async () => {
        try {
            setLoading(true)
            const { data, status } = await axios.post("https://lms.ixicities.com/api/v1/lms/freeflow/", {
                "subject": stateData?.topicName,
                "collection_id": currentUser?.school?.collectionId
            })
            if (status == 200) {
                console.log(data)
                setChatData([...chatData, { name: "chat", message: data?.response }])
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <StyledContainer>
            <StyledBox>
                {
                    chatData?.map((item, index) => {
                        return <div style={{
                            maxWidth: '50%',
                            borderColor: "rgba(227, 227, 227, 0.81)",
                            borderWidth: 1,
                            marginTop: index == 0 ? 0 : 20,
                            marginLeft: item?.name == "user" ? 0 : 10,
                            marginRight: item?.name == "user" ? 10 : 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 7,
                            paddingBottom: 7,
                            borderRadius: 15,
                            float: item?.name == "user" ? 'right' : 'left',
                            alignSelf: item?.name == "user" ? "flex-end" : "flex-start",
                            backgroundColor: item?.name == "user" ? "rgba(123, 20, 225, 1)" : "rgba(245, 245, 247, 1)"
                        }}>
                            {item?.name == "user" && <GText color={item?.name == "user" ? "white" : '#595959'} size={14}>{item?.message}</GText>}
                            {item?.name == "chat" && <GText color={'#595959'} size={14}>{item?.message}</GText>}
                        </div>
                    })}

                {
                    loading && <>
                        <div
                            style={{

                                marginTop: 10,
                               
                                marginRight: 0,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 7,
                                borderRadius: 15,

                                alignSelf: "flex-start",
                            }}

                        >
                            {/* <Tick /> */}
                            <GText mleft={10} color={'black'} size={14}>Searching for: {stateData?.topicName}</GText>
                        </div>

                        <div style={{
                           
                            marginRight: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 7,
                            borderRadius: 15,
                            alignSelf: "flex-start",
                        }}>
                            {/* <Tick /> */}
                            <GText mleft={10} color={'black'} size={14}>{"Generating answer for you..."}</GText>
                        </div>
                    </>
                }
            </StyledBox>
        </StyledContainer>
    );
}

export default Freeflow;

const styles = {
    inputField: {
        '& .MuiInputLabel-root': {
            color: '#838080',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#838080',
        },
    },
};

const StyledContainer = styledCom(Box)`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  justify-content: center;
  height:90%;
`;

const StyledBox = styledCom(Box)`
  width: 95%;
  height:100%;
  padding-top: 20px;
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
  display:flex;
  flex-direction:column;
  padding-bottom: 30px;
  overflow:auto
`;

const GText = styledCom.span`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props?.isDarkTheme ? 'white' : props.color};
  ${(props) => (props.weight ? `font-weight : ${props.weight}` : null)}
   ${(props) => (props.family ? `font-family : ${props.family}` : null)}
   ${(props) => (props.talign ? `text-align : ${props.talign}` : null)}
   ${(props) => (props.mleft ? `margin-left : ${props.mleft}px` : null)}
   ${(props) => (props.mright ? `margin-right : ${props.mright}px` : null)}
   ${(props) => (props.mtop ? `margin-top : ${props.mtop}px` : null)}
   ${(props) => (props.mbottom ? `margin-bottom : ${props.mbottom}px` : null)}
   ${(props) => (props.padding ? `padding : ${props.padding}px` : null)}
   ${(props) => (props.pleft ? `padding-left : ${props.pleft}px` : null)}
  ${(props) => (props.pright ? `padding-right : ${props.pright}px` : null)} 
  ${(props) => (props.ptop ? `padding-top : ${props.ptop}px` : null)}
   ${(props) => (props.pbottom ? `padding-bottom : ${props.pbottom}px` : null)}
   ${(props) =>
        props.underline ? `text-decoration-line : ${props.underline}` : null}
   ${(props) =>
        props.underline ? `text-decoration-line : ${props.underline}` : null}
   ${(props) => (props.position ? `position : ${props.position}` : null)}  
   ${(props) => (props.top ? `top : ${props.top}px` : null)}  
   ${(props) => (props.left ? `left : ${props.left}px` : null)}  
   ${(props) => (props.right ? `right : ${props.right}px` : null)}  
   ${(props) => (props.bottom ? `bottom : ${props.bottom}px` : null)}  
   ${(props) => (props.zindex ? `z-index : ${props.zindex}` : null)}
   ${(props) => (props.width ? `width : ${props.width}px` : null)}
   ${(props) => (props.letterSpacing ? `letter-spacing : ${props.letterSpacing}px` : null)}

`;

