import React, { useEffect, useState } from 'react';
import { Popover, Paper, Grid, Button, Typography } from '@mui/material';
import multiModal from "../../assets/multiModal.png";
import './Homepage.css';

const PopupContainer = ({ open, anchorEl, onClose, data, name }) => {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (!selected && data?.subpages[0]) {
      setSelected(data?.subpages[0]);
    }
  }, []);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}

    >
      <Paper className='glowing-border' onMouseLeave={onClose} sx={{ p: 2, backgroundColor: '#f5f5f5', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)' }}>
        <Grid container spacing={2}>
          <Grid item xs={6} alignItems={"center"} justifyContent={"center"} display={"flex"}>
            <img src={multiModal} alt="Image 1" style={{ width: '100%', height: 'auto' }} />
          </Grid>
          <Grid item xs={6} sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>{name || " "}</Typography>
            {data?.subpages && data?.subpages.map((subpage, index) => (
              <Button key={index} onClick={() => setSelected(subpage)} variant="outlined" sx={{ mb: 2, mr: 2 }}>
                <Typography variant="body1">{subpage}</Typography>
              </Button>
            ))}
            <Button onClick={onClose} variant="contained" sx={{ mb: 2, backgroundColor: "red" }}>Close</Button>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>{selected || "Stories"}</Typography>
            <Typography variant="body1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec nulla odio.
              Etiam ut elit et odio pharetra vehicula. Cras at enim ut libero tincidunt hendrerit.
              Nulla facilisi. Nullam efficitur ligula vel nunc rhoncus, et lacinia justo vehicula.
            </Typography>
            {/* Add more stories or content here */}
          </Grid>
        </Grid>
      </Paper>
    </Popover>
  );
};

export default PopupContainer;
