import React from "react";
import { Grid } from '@mui/material';
import { useDeviceType } from "../../components/deviceType";
import './Homepage.css';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import Apple from '../../assets/apple.png'
import Google from '../../assets/google.png'
import Twitter from '../../assets/twitter.png'
import Insta from '../../assets/insta.png'
import LinkedIn from '../../assets/linkedIn.png'
const Footer = () => {
    const deviceType = useDeviceType();

    // Define an array of objects for the footer links
    const footerLinks = [
        {
            title: "Useful Links",
            links: [
                { text: "Features", url: "/features" },
                { text: "About", url: "/about" },
                { text: "Service", url: "/service" },
                { text: "Team", url: "/team" }
            ]
        },
        {
            title: "Help & Support",
            links: [
                { text: "FAQ", url: "/faq" },
                { text: "Blog", url: "/blog" },
                { text: "Contact Us", url: "/contact" },
                { text: "Support", url: "/support" }
            ]
        },
        {
            title: "Resources",
            links: [
                { text: "Guides & resources", url: "/guides" },
                { text: "Team", url: "/resources-team" },
                { text: "Tools", url: "/tools" },
                { text: "Support", url: "/resources-support" }
            ]
        }
    ];
    const links = [
        { text: '(888) 1234-5678', url: 'tel:+188812345678', icon: <CallIcon color="#B4B6C4" /> },
        { text: 'info@example.com', url: 'mailto:info@example.com', icon: <EmailIcon color="#B4B6C4" /> },
    ];
    return (
        <Grid container spacing={0} alignItems="center" style={{ padding: '5%', paddingTop: "5%", backgroundColor: "#141F39" }}>
            <Grid style={{ padding: '1%', order: deviceType == "desktop" ? 1 : 2, paddingLeft: deviceType == "desktop" ? "5%" : 0, marginTop: deviceType != "desktop" ? "10%" : 0 }} item xs={12} md={6} >
                <p style={styles.text3}>Phasellus pulvinar porta turpis sit amet{deviceType}</p>
                <p style={{ ...styles.text3, paddingBottom: 15 }}>facilis sapien bibendum eu praesent massa.</p>
                {links.map((link, linkIndex) => (
                    <Grid item key={linkIndex}>
                        <a href={link.url} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                            <div style={styles.iconWrapper}>
                                {link.icon}
                            </div>
                            <p style={{ ...styles.text3 }}>{link.text}</p>
                        </a>
                    </Grid>
                ))}
                <div style={{
                    ...styles.box,
                    justifyContent: deviceType == "desktop" ? "flex-start" : "center",
                    marginLeft: deviceType != "desktop" ? "5%" : "0%",
                    width: deviceType == "desktop" ? "60%" : "90%",
                    marginTop: 15
                }}>
                    <img src={Google} alt="students" style={{ ...styles.image, height: 'auto' }} />
                    <img src={Apple} alt="students" style={{ ...styles.image, height: 'auto', marginLeft: 20 }} />
                </div>
            </Grid>
            <Grid style={{ padding: '1%', order: deviceType == "desktop" ? 2 : 1, paddingTop: deviceType == "desktop" ? 0 : "10%" }} justifyContent="space-between" flexDirection="row" display="flex" item xs={12} md={6}>
                {footerLinks.map((section, index) => (
                    <Grid key={index} item xs={6} md={4}>
                        <p style={{
                            ...styles.text1,
                            textAlign: deviceType != "desktop" ? index === 0 ? 'left' : index === 2 ? 'right' : 'center' : 'center',
                            marginBottom: 15,
                        }}>{section.title}</p>
                        {section.links.map((link, linkIndex) => (
                            <p key={linkIndex} style={{ ...styles.text2, marginBottom: 15, lineHeight: '0.9rem', textAlign: deviceType != "desktop" ? index === 0 ? 'left' : index === 2 ? 'right' : 'center' : 'center', }}>
                                <a href={link.url}>{link.text}</a>
                            </p>
                        ))}
                    </Grid>
                ))}
            </Grid>
            <Grid style={{ padding: '1%', order: deviceType == "desktop" ? 4 : 3, marginTop: deviceType != "desktop" ? "10%" : "3%" }} alignItems={deviceType == "desktop" ? "flex-end" : "center"} item xs={12} md={6} >
                <div style={{
                    ...styles.box,
                    justifyContent: deviceType == "desktop" ? "flex-end" : "center",
                    width: deviceType == "desktop" ? "40%" : "100%",
                    marginBottom: deviceType == "desktop" ? 0 : 15,
                    marginLeft: deviceType == "desktop" ? "60%" : 0,
                }}>
                    <img src={Insta} alt="students" style={{ ...styles.image, height: deviceType == "tablet" ? "2.1rem" : '1.875rem', width: deviceType == "tablet" ? "2.1rem" : '1.875rem' }} />
                    <img src={Twitter} alt="students" style={{ ...styles.image, height: deviceType == "tablet" ? "2.1rem" : '1.875rem', width: deviceType == "tablet" ? "2.1rem" : '1.875rem' }} />
                    <img src={LinkedIn} alt="students" style={{ ...styles.image, height: deviceType == "tablet" ? "2.1rem" : '1.875rem', width: deviceType == "tablet" ? "2.1rem" : '1.875rem' }} />
                </div>
            </Grid>
            <Grid style={{
                padding: '1%', order: deviceType == "desktop" ? 3 : 4,
                marginTop: deviceType == "desktop" ? "3%" : 0,
                paddingLeft: deviceType == "desktop" ? "5%" : 0,
                justifyContent: deviceType == "desktop" ? "flex-start" : "center",
            }} item xs={12} md={6} >
                <p style={{
                    ...styles.text2,
                    textAlign: deviceType == "desktop" ? "left" : "center",
                    fontSize: '1rem',
                }}>© All Right Reserved</p>
            </Grid>
        </Grid >
    );
}

export default Footer;

const styles = {
    text1: {
        fontSize: '1rem',
        fontWeight: '700',
        textAlign: "center",
        color: "white",
        lineHeight: '0.9rem',
        marginBottom: 10,
    },
    text2: {
        fontSize: '0.9rem',
        fontWeight: '400',
        textAlign: "center",
        color: "#B4B6C4",
        lineHeight: '2rem',
    },
    text3: {
        fontSize: '0.9rem',
        fontWeight: '400',
        color: "#B4B6C4",
        lineHeight: '2rem',
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px',
        color: "#B4B6C4",
    },
    image: {
        width: "50%",
        display: 'block',
        margin: 'auto',
    },
    box: {
        width: "50%",
        alignItems: 'center',
        display: "flex",
        flexDirection: "row",
        width: "60%",
    },
};
