import { useMediaQuery } from '@mui/material';

export const useDeviceType = () => {
  const isMobile = useMediaQuery('(max-width:450px)');
  const isTablet = useMediaQuery('(max-width:900px)');
  const isDesktop = !(isMobile || isTablet);

  if (isMobile) {
    return 'mobile';
  } else if (isTablet) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};
