import React from "react";
import Carousel from "./HomePageComponents/carousel";
import Banner from "./HomePageComponents/banner";
import MiddleScreen from "./HomePageComponents/middleScreen";
import Plans from "./HomePageComponents/Plans";
import BottomScreen from "./HomePageComponents/bottomScreen";
import "./HomePageComponents/Homepage.css";
// import { useDeviceType } from "../components/deviceType";
import Assessment from "./HomePageComponents/Assessment";
import MultiModal from "./HomePageComponents/MultiModal";
import LastBox from "./HomePageComponents/lastBox";
import Footer from "./HomePageComponents/footer";
import NavBar from "./HomePageComponents/NavBar";

const ChoosePlan = () => {
  return (
    <div className="main-container" style={{height:'100vh'}}>
      <Plans />
    </div>
  );
};


export default ChoosePlan;