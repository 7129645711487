import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSubjectList } from '../../redux/sclassRelated/sclassHandle';
import { BottomNavigation, BottomNavigationAction, Container, Paper, Table, TableBody, TableHead, Typography } from '@mui/material';
import { getUserDetails } from '../../redux/userRelated/userHandle';
import CustomBarChart from '../../components/CustomBarChart'

import InsertChartIcon from '@mui/icons-material/InsertChart';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { StyledTableCell, StyledTableRow } from '../../components/styles';
import TableTemplate from '../../components/TableTemplate';
import { BlueButton } from '../../components/buttonStyles';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useDeviceType } from "../../components/deviceType";
const StudentSubjects = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const deviceType = useDeviceType();
    const { subjectsList, sclassDetails } = useSelector((state) => state.sclass);
    const { userDetails, currentUser, loading, response, error } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(getUserDetails(currentUser._id, "Student"));
    }, [dispatch, currentUser._id])

    if (response) { console.log(response) }
    else if (error) { console.log(error) }

    const [subjectMarks, setSubjectMarks] = useState([]);
    const [selectedSection, setSelectedSection] = useState('table');

    useEffect(() => {
        if (userDetails) {
            setSubjectMarks(userDetails.examResult || []);
        }
    }, [userDetails])

    useEffect(() => {
        // if (subjectMarks === []) {
        dispatch(getSubjectList(currentUser.sclassName._id, "ClassSubjects"));
        // }
    }, [subjectMarks, dispatch, currentUser.sclassName._id]);

    const handleSectionChange = (event, newSection) => {
        setSelectedSection(newSection);
    };

    const renderTableSection = () => {
        return (
            <>
                {/* <Typography variant="h4" align="center" gutterBottom>
                    Subject Marks
                </Typography> */}
                <Table>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Subject</StyledTableCell>
                            <StyledTableCell>Subject code</StyledTableCell>
                            <StyledTableCell>Class Teacher</StyledTableCell>
                            <StyledTableCell>Class</StyledTableCell>
                            <StyledTableCell>Topic count</StyledTableCell>
                            <StyledTableCell>Subject Image</StyledTableCell>


                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {subjectsList.map((result, index) => {
                            // if (!result.subName || !result.marksObtained) {
                            //     return null;
                            // }
                            return (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{result.subName}</StyledTableCell>
                                    <StyledTableCell>{result.subCode}</StyledTableCell>
                                    <StyledTableCell>{result.teacher?.name}</StyledTableCell>
                                    <StyledTableCell>{result.sclassName?.sclassName}</StyledTableCell>
                                    <StyledTableCell>{result.toipics?.length}</StyledTableCell>
                                    <img src={result.subImage?.Location} style={{ width: 50, height: 50, borderRadius: 50, marginTop: 5 }} />
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </>
        );
    };

    const renderChartSection = () => {
        return <CustomBarChart chartData={subjectMarks} dataKey="marksObtained" />;
    };

    const renderClassDetailsSection = () => {
        return (
            <Container>
                <Typography variant="h4" align="center" gutterBottom>
                    Class Details
                </Typography>
                <Typography variant="h5" gutterBottom>
                    You are currently in Class {sclassDetails && sclassDetails.sclassName}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    And these are the subjects:
                </Typography>
                {subjectsList &&
                    subjectsList.map((subject, index) => (
                        <div key={index}>
                            <Typography variant="subtitle1">
                                {subject.subName} ({subject.subCode})
                            </Typography>
                        </div>
                    ))}
            </Container>
        );
    };

    const subjectColumns = [
        { id: 'subName', label: 'Sub Name', minWidth: 170 },
        { id: 'subCode', label: 'Sub Code', minWidth: 170 },

        // { id: 'sessions', label: 'Sessions', minWidth: 170 },
        { id: 'sclassName', label: 'Class', minWidth: 170 },
    ]

    const subjectRows = subjectsList.map((subject) => {
        return {
            subName: subject.subName,
            subCode: subject.subCode,
            sclassName: subject.sclassName.sclassName,
            sclassID: subject.sclassName._id,
            id: subject._id,
        };
    })

    const SubjectsButtonHaver = ({ row }) => {
        return (
            <>

                <BlueButton variant="contained"
                    onClick={() => navigate(`/Student/subjects/subject/${row.sclassID}/${row.id}`)}
                >
                    View
                </BlueButton>
            </>
        );
    };

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div>
                    <Grid container spacing={2} justifyContent={deviceType == "desktop" ? "flex-start" : "space-between"} alignItems="center"
                        style={{
                            // backgroundColor: 'red',
                            paddingLeft: "5%", paddingRight: "5%", paddingTop: "2%", paddingBottom: "2%"
                        }}>
                        {subjectsList &&
                            subjectsList.map((subject, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={index}
                                    onClick={() => navigate(`/Student/subjects/topics/${subject._id}`)}
                                    // style={{ background: "green", }} 
                                    justifyContent="center" alignItems="center" >
                                    <div style={styles.imageBox}>
                                        <div style={{ ...styles.paper,  width: '100%', height: 200 }}>
                                            <img src={subject.subImage.Location} alt="students" style={{ ...styles.image, borderRadius: 8, objectFit: "contain", maxWidth: "100%", height: "auto", maxHeight: 200, width: "auto" }} />
                                        </div>
                                        <Typography variant="subtitle1" style={{ color: "black", fontSize: 18, fontWeight: 600, marginTop: 20 }}>
                                            {subject.subName}
                                        </Typography>
                                    </div>

                                </Grid>
                            ))}
                    </Grid>
                </div >
            )}
        </>
    );
};
const styles = {
    paper: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 18,
        overflow: "hidden",
    },
    image: {
        objectFit: "contain",
        maxWidth: "100%",
        maxHeight: 200,
        width: "auto",
        height: "auto",
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        borderColor: "#d3d3d3",
    },
    imageBox: {
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        borderColor: "#d3d3d3",
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop: '5%',
        paddingBottom: '5%',
        marginBottom: "5%",
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: "white"
    }
}

export default StudentSubjects;